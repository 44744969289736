import { Component, ElementRef, NgZone, ViewChild } from "@angular/core";
import { ScrollDispatcher } from "@angular/cdk/overlay";

@Component({
  selector: 'lib-sticky-footer',
  template: `
    <div *ngIf="show" id="sticky-footer" class="d-block d-lg-none">
      <div class="row justify-content-between">
        <div class="col-9 d-flex justify-content-center align-items-center boshhh-my-credit-score bt white-button-credit">
          <span class="text-center pt-1" style="letter-space: 0 !important;">
            <a class="boshhh-my-credit-score-a fw-bold" href="/create-account">Boshhh my Credit Score</a>
          </span>
        </div>
        <a href="https://wa.me/+447398871718?text=" class="col-3 text-center white-button-whatsapp">
          <img class="whatsapp-icon" src="../assets/images/whatsapp-icon.png" />
        </a>
      </div>
    </div>
  `,
  styles: [`
    #sticky-footer {
      width: 100%;
      background-color: transparent;
      background-image: url('https://boshhh.com/assets/images/background.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      color: #F2F2F2;
      z-index: 100;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
    }
    
    
    .boshhh-my-credit-score a {
      font-size: 1.2rem;
      text-decoration: none;
      background: -webkit-linear-gradient(45deg, #009245, #003f37);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .whatsapp-icon {
      width:60px;
    }

    .white-button-credit {
      background-color: white;
      border-radius: 30px;
      padding: 5px 20px;
      
    }
    .white-button-whatsapp {
      
      // padding: 5px 20px;
    }
    .row{
        display: flex;
        flex-wrap: nowrap;
        padding: 20px;
        justify-content: center;
    }
  `]
})
export class StickyFooterComponent {

  show: boolean = false;

  stickFooterElement: HTMLElement;
  @ViewChild('stream') set stickyFooterRef(ref: ElementRef<HTMLAudioElement>) {
    if (ref !== undefined) {
      this.stickFooterElement = ref.nativeElement;
    }
  }

  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.scrollDispatcher.scrolled().subscribe((cdk: any) => {
      this.zone.run(() => {
        if (cdk.getElementRef().nativeElement != undefined) {
          const scrollPosition = cdk.getElementRef().nativeElement.scrollTop;
          this.show = scrollPosition > 100;
        }
      });
    });
  }
}
