import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatDrawer } from "@angular/material/sidenav";

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() useHelloFooter: boolean;

  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

  public innerWidth: any;

  production: boolean = false;

  constructor(
    private router: Router,
    // @ts-ignore
    @Inject('env') private env
  ) {
    this.production = this.env.production;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  public navigate (route: string, doToggle: boolean): void {
    if (doToggle)
      this.drawer.toggle();

    console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
}
