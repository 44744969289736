import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'content-how-to-and-faqs',
  templateUrl: './how-to-and-faqs.component.html',
  styles: [],
})
export class HowToAndFaqsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
