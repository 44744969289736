import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'content-about-us',
  templateUrl: './about-us.component.html',
  styles: [],
})
export class AboutUsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
