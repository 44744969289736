import { Component } from '@angular/core';

@Component({
  selector: 'lib-frequently-asked-questions',
  template: `
    <div class="text-center pt-5 container">

      <h1 style="margin: 1rem 0;">
        FAQs.
      </h1>
      <mat-accordion class="example-headers-align text-start " multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                What is the coverage like with Boshhh?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="text-black">
            Wherever it can, Boshhh connects to 5G, 4G, and 3G networks. 2G mobile devices are incompatible with Boshhh.
            Before using your device to access our 5G network, make sure it is 5G-capable.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                Can I bring my number across?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="text-black">
            Get a PAC code by simply contacting your current network provider. You can do this over the phone, online, or
            by texting the word "PAC" to 65075. After that, your PAC is good for 30 days. This can be entered at the time
            you order your new Boshhh SIM or at a later time. The transfer will then take place by the end of the following
            working day thanks to our collaboration with your current network provider. You won't experience any service
            interruption if you already have an active Boshhh SIM, but you might need to restart your device for the
            transfer to take effect.
            
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                Does the cost increase every year?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="text-black">
            Boshhh is quite affordable. Plans that roll over for 30 days may be cancelled at any time. This means that
            during the course of your contracts, you are never required to accept price hikes. That is what we call BOSHHH.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                I am travelling to the EU, what is included?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="text-black">
            With BOSHHH EU Roaming service, you can use all of your monthly data allotment, up to 12GB per month, as well
            as your unlimited calls and texts to UK numbers while travelling within those EU nations. If we discover that
            you have used our services continuously for at least 60 days outside of the UK during a rolling 12-month
            period, we may suspend your service.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                How do I get in contact with you?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="text-black">
            Speak to one of our customer service team or visit our contact page.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header style="margin-bottom: 10px;">
            <mat-panel-title>
              <h4 class="pt-3 text-green-gradient">
                Can I leave Boshhh?
              </h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p class="text-black">
            Remember that if you're unsure about leaving, you can always temporarily pause your plan. We'll miss you
            terribly. Please do not hesitate to get in touch with our customer support staff if there is anything we can do
            to assist or learn from.
          </p>
        </mat-expansion-panel>

      </mat-accordion>
    </div>
  `,
  styles: [`
    .text-green-gradient {
      background: -webkit-linear-gradient(45deg, #009245, #003f37);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `]
})
export class FrequentlyAskedQuestionsComponent {
  constructor() {}
}
