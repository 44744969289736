import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'app-mobiles',
  template: `
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css" rel="stylesheet">
    <link rel="stylesheet" href="styles/style.css">

    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700;800&display=swap"
          rel="stylesheet">


    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/js/bootstrap.bundle.min.js"></script>
    <div class="my-1 p-0">
      <img src="assets/img/color_line.png" width="100%">
    </div>

    <div class="container">
      <h1 class="text-center">Refurbished Phones</h1>
      <div class="features">
        <ul class="p-0">
          <li class="d-flex align-items-center">
            <img src="../../assets/img/warrenty.png" alt="warrenty" srcset="">
            <h5 class="my-1">Minimum 12-month warrenty</h5>
          </li>
          <li class="d-flex align-items-center">
            <img src="assets/img/sustainable.png" alt="warrenty" srcset="">
            <h5 class="my-1">Open more sustainable option</h5>
          </li>
          <li class="d-flex align-items-center">
            <img src="assets/img/delivery.png" alt="warrenty" srcset="">
            <h5 class="my-1">Free <b>next business day delivery</b></h5>
          </li>
          <li class="d-flex align-items-center">
            <img src="assets/img/battery.png" alt="warrenty" srcset="">
            <h5 class="my-1">80% battery heath, or bettery</h5>
          </li>

        </ul>
      </div>
    </div>


    <div class="container">
      <ul class="nav nav-pills mb-3 justify-content-between pt-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="pills-new-tab" data-bs-toggle="pill" data-bs-target="#pills-new"
                  type="button" role="tab" aria-controls="pills-home" aria-selected="true">New
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-refurbish-tab" data-bs-toggle="pill" data-bs-target="#pills-refurbish"
                  type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Refurbished
          </button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-new" role="tabpanel" aria-labelledby="pills-new-tab"
             tabindex="0">
          <div class="d-flex justify-content-between filter_by">
            <div class="dropdown">
              <button class="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                Filter By
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Filter 1</a></li>
                <li><a class="dropdown-item" href="#">Filter 2</a></li>
                <li><a class="dropdown-item" href="#">Filter 3</a></li>
              </ul>
            </div>
            <div class="dropdown">
              <button class="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
                      aria-expanded="false">
                Sort By
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Price</a></li>
                <li><a class="dropdown-item" href="#">Condition</a></li>
                <li><a class="dropdown-item" href="#">Warrenty</a></li>

              </ul>
            </div>
          </div>
          <hr>
          <div class="d-flex align-items-center">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" checked name="new_phone_type" id="new_all_phone"
                     value="all phone">
              <label class="form-check-label" for="new_all_phone">All Phones</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="new_phone_type" id="new_iphone" value="iphone">
              <label class="form-check-label" for="new_iphone">Iphone</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="new_phone_type" id="new_Samsung" value="Samsung">
              <label class="form-check-label" for="new_Samsung">Samsung</label>
            </div>
          </div>
          <hr>
          <div class="form-check form-switch">
            <label class="form-check-label" for="out_of_stock">Hide out of stock</label>
            <input class="form-check-input" type="checkbox" role="switch" id="out_of_stock" checked>

          </div>
          <div class="product row">

            <div class="card col-md-4 my-4 ">
              <h2 class="text-center">iPhone 8 New</h2>
              <div class="img_block d-flex justify-content-center position-relative p-5">
                <img src="assets/img/iphone8.png" width="250px">

                <div class="position-absolute discount">
                  <h2>£40</h2>
                  <p>OFF</p>
                </div>
              </div>
              <div class="d-flex justify-content-center flex-column align-items-center">
                <p><span>From</span> <b>£99</b></p>
                <div class="d-flex  align-items-center p-3">
                  <img src="assets/img/tick.png" width="24px">
                  <p class="mx-2 my-0"><b>Now in stock</b></p>
                </div>
              </div>
              <div class="colors_pallete d-flex align-items-center justify-content-center">
                <a href="#">
                  <div class="bg-success"></div>
                </a>
                <a href="#">
                  <div class="bg-danger"></div>
                </a>
                <a href="#">
                  <div class="bg-primary"></div>
                </a>
                <a href="#">
                  <div class="bg-secondary"></div>
                </a>
                <a href="#">
                  <div class="bg-info"></div>
                </a>
              </div>
            </div>
            <div class="card product-style-2 col-md-4 my-4 ">
              <h2 class="text-center">iPhone 8 New</h2>
              <div class="img_block d-flex justify-content-center position-relative p-5">
                <img src="assets/img/iphone-11-pro-midnight-green-select-2019.png" width="250px">

                <div class="position-absolute discount">
                  <h2>£50</h2>
                  <p>OFF</p>
                </div>
              </div>
              <div class="d-flex justify-content-between specs py-4">
                <div class="d-flex block_1 justify-content-center flex-column align-items-center ">
                  <p><span>From</span> <b>£99</b></p>
                  <div class="d-flex  align-items-center">
                    <img src="assets/img/tick.png" width="24px">
                    <p class="mx-2 my-0">Now in stock</p>
                  </div>
                </div>
                <div class="px-3">
                  <p>or 24 monthly payments</p>
                  <div class="d-flex justify-content-between">
                    <div>
                      <p><b>£11.42</b></p>
                      <p>a month</p>
                    </div>
                    <div>
                      <p><b>£25</b></p>
                      <p>upfront</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colors_pallete d-flex align-items-center justify-content-center">
                <a href="#">
                  <div class="bg-success"></div>
                </a>
                <a href="#">
                  <div class="bg-danger"></div>
                </a>
                <a href="#">
                  <div class="bg-primary"></div>
                </a>
                <a href="#">
                  <div class="bg-secondary"></div>
                </a>
                <a href="#">
                  <div class="bg-info"></div>
                </a>
              </div>
            </div>
            <div class="card product-style-2 col-md-4 my-4 ">
              <h2 class="text-center">iPhone 8 New</h2>
              <div class="img_block d-flex justify-content-center position-relative p-5">
                <img src="assets/img/IPHOEN13.png" width="250px">

                <div class="position-absolute discount">
                  <h2>£50</h2>
                  <p>OFF</p>
                </div>
              </div>
              <div class="d-flex justify-content-between specs py-4">
                <div class="d-flex block_1 justify-content-center flex-column align-items-center ">
                  <p><span>From</span> <b>£299</b></p>
                  <div class="d-flex  align-items-center">
                    <img src="assets/img/tick.png" width="24px">
                    <p class="mx-2 my-0">Now in stock</p>
                  </div>
                </div>
                <div class="px-3">
                  <p>or 24 monthly payments</p>
                  <div class="d-flex justify-content-between">
                    <div>
                      <p><b>£11.42</b></p>
                      <p>a month</p>
                    </div>
                    <div>
                      <p><b>£25</b></p>
                      <p>upfront</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colors_pallete d-flex align-items-center justify-content-center">
                <a href="#">
                  <div class="bg-success"></div>
                </a>
                <a href="#">
                  <div class="bg-danger"></div>
                </a>
                <a href="#">
                  <div class="bg-primary"></div>
                </a>
                <a href="#">
                  <div class="bg-secondary"></div>
                </a>
                <a href="#">
                  <div class="bg-info"></div>
                </a>
              </div>
            </div>


          </div>
          <div class="product row">

            <div class="card col-md-4 my-4 ">
              <h2 class="text-center">iPhone 8 New</h2>
              <div class="img_block d-flex justify-content-center position-relative p-5">
                <img src="assets/img/iphone8.png" width="250px">

                <div class="position-absolute discount">
                  <h2>£40</h2>
                  <p>OFF</p>
                </div>
              </div>
              <div class="d-flex justify-content-center flex-column align-items-center">
                <p><span>From</span> <b>£99</b></p>
                <div class="d-flex  align-items-center p-3">
                  <img src="assets/img/tick.png" width="24px">
                  <p class="mx-2 my-0"><b>Now in stock</b></p>
                </div>
              </div>
              <div class="colors_pallete d-flex align-items-center justify-content-center">
                <a href="#">
                  <div class="bg-success"></div>
                </a>
                <a href="#">
                  <div class="bg-danger"></div>
                </a>
                <a href="#">
                  <div class="bg-primary"></div>
                </a>
                <a href="#">
                  <div class="bg-secondary"></div>
                </a>
                <a href="#">
                  <div class="bg-info"></div>
                </a>
              </div>
            </div>
            <div class="card product-style-2 col-md-4 my-4 ">
              <h2 class="text-center">iPhone 8 New</h2>
              <div class="img_block d-flex justify-content-center position-relative p-5">
                <img src="assets/img/iphone-11-pro-midnight-green-select-2019.png" width="250px">

                <div class="position-absolute discount">
                  <h2>£50</h2>
                  <p>OFF</p>
                </div>
              </div>
              <div class="d-flex justify-content-between specs py-4">
                <div class="d-flex block_1 justify-content-center flex-column align-items-center ">
                  <p><span>From</span> <b>£99</b></p>
                  <div class="d-flex  align-items-center">
                    <img src="assets/img/tick.png" width="24px">
                    <p class="mx-2 my-0">Now in stock</p>
                  </div>
                </div>
                <div class="px-3">
                  <p>or 24 monthly payments</p>
                  <div class="d-flex justify-content-between">
                    <div>
                      <p><b>£11.42</b></p>
                      <p>a month</p>
                    </div>
                    <div>
                      <p><b>£25</b></p>
                      <p>upfront</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colors_pallete d-flex align-items-center justify-content-center">
                <a href="#">
                  <div class="bg-success"></div>
                </a>
                <a href="#">
                  <div class="bg-danger"></div>
                </a>
                <a href="#">
                  <div class="bg-primary"></div>
                </a>
                <a href="#">
                  <div class="bg-secondary"></div>
                </a>
                <a href="#">
                  <div class="bg-info"></div>
                </a>
              </div>
            </div>
            <div class="card product-style-2 col-md-4 my-4 ">
              <h2 class="text-center">iPhone 8 New</h2>
              <div class="img_block d-flex justify-content-center position-relative p-5">
                <img src="assets/img/IPHOEN13.png" width="250px">

                <div class="position-absolute discount">
                  <h2>£50</h2>
                  <p>OFF</p>
                </div>
              </div>
              <div class="d-flex justify-content-between specs py-4">
                <div class="d-flex block_1 justify-content-center flex-column align-items-center ">
                  <p><span>From</span> <b>£299</b></p>
                  <div class="d-flex  align-items-center">
                    <img src="assets/img/tick.png" width="24px">
                    <p class="mx-2 my-0">Now in stock</p>
                  </div>
                </div>
                <div class="px-3">
                  <p>or 24 monthly payments</p>
                  <div class="d-flex justify-content-between">
                    <div>
                      <p><b>£11.42</b></p>
                      <p>a month</p>
                    </div>
                    <div>
                      <p><b>£25</b></p>
                      <p>upfront</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colors_pallete d-flex align-items-center justify-content-center">
                <a href="#">
                  <div class="bg-success"></div>
                </a>
                <a href="#">
                  <div class="bg-danger"></div>
                </a>
                <a href="#">
                  <div class="bg-primary"></div>
                </a>
                <a href="#">
                  <div class="bg-secondary"></div>
                </a>
                <a href="#">
                  <div class="bg-info"></div>
                </a>
              </div>
            </div>


          </div>
          <div class="product row">

            <div class="card col-md-4 my-4 ">
              <h2 class="text-center">iPhone 8 New</h2>
              <div class="img_block d-flex justify-content-center position-relative p-5">
                <img src="assets/img/iphone8.png" width="250px">

                <div class="position-absolute discount">
                  <h2>£40</h2>
                  <p>OFF</p>
                </div>
              </div>
              <div class="d-flex justify-content-center flex-column align-items-center">
                <p><span>From</span> <b>£99</b></p>
                <div class="d-flex  align-items-center p-3">
                  <img src="assets/img/tick.png" width="24px">
                  <p class="mx-2 my-0"><b>Now in stock</b></p>
                </div>
              </div>
              <div class="colors_pallete d-flex align-items-center justify-content-center">
                <a href="#">
                  <div class="bg-success"></div>
                </a>
                <a href="#">
                  <div class="bg-danger"></div>
                </a>
                <a href="#">
                  <div class="bg-primary"></div>
                </a>
                <a href="#">
                  <div class="bg-secondary"></div>
                </a>
                <a href="#">
                  <div class="bg-info"></div>
                </a>
              </div>
            </div>
            <div class="card product-style-2 col-md-4 my-4 ">
              <h2 class="text-center">iPhone 8 New</h2>
              <div class="img_block d-flex justify-content-center position-relative p-5">
                <img src="assets/img/iphone-11-pro-midnight-green-select-2019.png" width="250px">

                <div class="position-absolute discount">
                  <h2>£50</h2>
                  <p>OFF</p>
                </div>
              </div>
              <div class="d-flex justify-content-between specs py-4">
                <div class="d-flex block_1 justify-content-center flex-column align-items-center ">
                  <p><span>From</span> <b>£99</b></p>
                  <div class="d-flex  align-items-center">
                    <img src="assets/img/tick.png" width="24px">
                    <p class="mx-2 my-0">Now in stock</p>
                  </div>
                </div>
                <div class="px-3">
                  <p>or 24 monthly payments</p>
                  <div class="d-flex justify-content-between">
                    <div>
                      <p><b>£11.42</b></p>
                      <p>a month</p>
                    </div>
                    <div>
                      <p><b>£25</b></p>
                      <p>upfront</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colors_pallete d-flex align-items-center justify-content-center">
                <a href="#">
                  <div class="bg-success"></div>
                </a>
                <a href="#">
                  <div class="bg-danger"></div>
                </a>
                <a href="#">
                  <div class="bg-primary"></div>
                </a>
                <a href="#">
                  <div class="bg-secondary"></div>
                </a>
                <a href="#">
                  <div class="bg-info"></div>
                </a>
              </div>
            </div>
            <div class="card product-style-2 col-md-4 my-4 ">
              <h2 class="text-center">iPhone 8 New</h2>
              <div class="img_block d-flex justify-content-center position-relative p-5">
                <img src="assets/img/IPHOEN13.png" width="250px">

                <div class="position-absolute discount">
                  <h2>£50</h2>
                  <p>OFF</p>
                </div>
              </div>
              <div class="d-flex justify-content-between specs py-4">
                <div class="d-flex block_1 justify-content-center flex-column align-items-center ">
                  <p><span>From</span> <b>£299</b></p>
                  <div class="d-flex  align-items-center">
                    <img src="assets/img/tick.png" width="24px">
                    <p class="mx-2 my-0">Now in stock</p>
                  </div>
                </div>
                <div class="px-3">
                  <p>or 24 monthly payments</p>
                  <div class="d-flex justify-content-between">
                    <div>
                      <p><b>£11.42</b></p>
                      <p>a month</p>
                    </div>
                    <div>
                      <p><b>£25</b></p>
                      <p>upfront</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colors_pallete d-flex align-items-center justify-content-center">
                <a href="#">
                  <div class="bg-success"></div>
                </a>
                <a href="#">
                  <div class="bg-danger"></div>
                </a>
                <a href="#">
                  <div class="bg-primary"></div>
                </a>
                <a href="#">
                  <div class="bg-secondary"></div>
                </a>
                <a href="#">
                  <div class="bg-info"></div>
                </a>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-refurbish" role="tabpanel" aria-labelledby="pills-refurbish-tab"
           tabindex="0">
        <div class="d-flex justify-content-between filter_by">
          <div class="dropdown">
            <button class="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Filter By
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Filter 1</a></li>
              <li><a class="dropdown-item" href="#">Filter 2</a></li>
              <li><a class="dropdown-item" href="#">Filter 3</a></li>
            </ul>
          </div>
          <div class="dropdown">
            <button class="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Sort By
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Price</a></li>
              <li><a class="dropdown-item" href="#">Condition</a></li>
              <li><a class="dropdown-item" href="#">Warrenty</a></li>

            </ul>
          </div>
        </div>
        <hr>
        <div class="d-flex align-items-center">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" checked name="phone_type" id="all_phone" value="option1">
            <label class="form-check-label" for="all_phone">All Phones</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="phone_type" id="iphone" value="iphone">
            <label class="form-check-label" for="iphone">Iphone</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="phone_type" id="Ssamsung" value="Ssamsung">
            <label class="form-check-label" for="Ssamsung">Samsung</label>
          </div>
        </div>
        <hr>
        <div class="form-check form-switch">
          <label class="form-check-label" for="out_of_stock">Hide out of stock</label>
          <input class="form-check-input" type="checkbox" role="switch" id="out_of_stock" checked>

        </div>
        <div class="product">

          <div class="card col-md-4 my-4 ">
            <h2 class="text-center">iPhone 8 Refurbished</h2>
            <div class="img_block d-flex justify-content-center position-relative p-5">
              <img src="assets/img/iphone8.png" width="250px">

              <div class="position-absolute discount">
                <h2>£40</h2>
                <p>OFF</p>
              </div>
            </div>
            <div class="d-flex justify-content-center flex-column align-items-center">
              <p><span>From</span> <b>£99</b></p>
              <div class="d-flex  align-items-center p-3">
                <img src="assets/img/tick.png" width="24px">
                <p class="mx-2 my-0"><b>Now in stock</b></p>
              </div>
            </div>
            <div class="colors_pallete d-flex align-items-center justify-content-center">
              <a href="#">
                <div class="bg-success"></div>
              </a>
              <a href="#">
                <div class="bg-danger"></div>
              </a>
              <a href="#">
                <div class="bg-primary"></div>
              </a>
              <a href="#">
                <div class="bg-secondary"></div>
              </a>
              <a href="#">
                <div class="bg-info"></div>
              </a>
            </div>
          </div>
          <div class="card product-style-2 col-md-4 my-4 ">
            <h2 class="text-center">iPhone 8 Refurbished</h2>
            <div class="img_block d-flex justify-content-center position-relative p-5">
              <img src="assets/img/iphone-11-pro-midnight-green-select-2019.png" width="250px">

              <div class="position-absolute discount">
                <h2>£50</h2>
                <p>OFF</p>
              </div>
            </div>
            <div class="d-flex justify-content-between specs py-4">
              <div class="d-flex block_1 justify-content-center flex-column align-items-center ">
                <p><span>From</span> <b>£99</b></p>
                <div class="d-flex  align-items-center">
                  <img src="assets/img/tick.png" width="24px">
                  <p class="mx-2 my-0">Now in stock</p>
                </div>
              </div>
              <div class="px-3">
                <p>or 24 monthly payments</p>
                <div class="d-flex justify-content-between">
                  <div>
                    <p><b>£11.42</b></p>
                    <p>a month</p>
                  </div>
                  <div>
                    <p><b>£25</b></p>
                    <p>upfront</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="colors_pallete d-flex align-items-center justify-content-center">
              <a href="#">
                <div class="bg-success"></div>
              </a>
              <a href="#">
                <div class="bg-danger"></div>
              </a>
              <a href="#">
                <div class="bg-primary"></div>
              </a>
              <a href="#">
                <div class="bg-secondary"></div>
              </a>
              <a href="#">
                <div class="bg-info"></div>
              </a>
            </div>
          </div>
          <div class="card product-style-2 col-md-4 my-4 ">
            <h2 class="text-center">iPhone 8 Refurbished</h2>
            <div class="img_block d-flex justify-content-center position-relative p-5">
              <img src="assets/img/IPHOEN13.png" width="250px">

              <div class="position-absolute discount">
                <h2>£50</h2>
                <p>OFF</p>
              </div>
            </div>
            <div class="d-flex justify-content-between specs py-4">
              <div class="d-flex block_1 justify-content-center flex-column align-items-center ">
                <p><span>From</span> <b>£299</b></p>
                <div class="d-flex  align-items-center">
                  <img src="assets/img/tick.png" width="24px">
                  <p class="mx-2 my-0">Now in stock</p>
                </div>
              </div>
              <div class="px-3">
                <p>or 24 monthly payments</p>
                <div class="d-flex justify-content-between">
                  <div>
                    <p><b>£11.42</b></p>
                    <p>a month</p>
                  </div>
                  <div>
                    <p><b>£25</b></p>
                    <p>upfront</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="colors_pallete d-flex align-items-center justify-content-center">
              <a href="#">
                <div class="bg-success"></div>
              </a>
              <a href="#">
                <div class="bg-danger"></div>
              </a>
              <a href="#">
                <div class="bg-primary"></div>
              </a>
              <a href="#">
                <div class="bg-secondary"></div>
              </a>
              <a href="#">
                <div class="bg-info"></div>
              </a>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div class="my-1 p-0">
      <img src="assets/img/color_line.png" width="100%">
    </div>

  `,
  styles: [`

  `],
})
export class MobilesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
