import { Component, Input } from "@angular/core";

@Component({
  selector: 'lib-plan-featured-mobile',
  template: `
    <div class="row pl-3 pt-0 ">
      <div class="col-5 pt-3 text-start">
        <h2 class="mb-0 lh-2 fw-bolder text-light" style="font-family: 'Poppins', Helvetica, sans-serif !important;" [innerHTML]="title"></h2>
        <h1 class="mb-0 lh-auto" [innerHTML]="price"></h1>
      </div>
      <div class="col-7 text-end pt-0 pr-3">
        <div class="btn btn-plan-top col-12 pt-3 pr-2 pb-3 mb-1 mt-1 mr-1 " hidden>
          <img alt="Credit builder plus" src="/assets/images/credit-builder-plus.png" class="col-12" hidden />
        </div>
      </div>
    </div>
    <div class="row pl-3 pt-0 pr-0 pb-3">
      <div class="col-4">
        <div class="sim-card-container col-12">
          <img alt="SIM Card" src="/assets/images/sim-card-icon.png" class="col-9" />
        </div>
      </div>
      <div class="col-8">
        <ul class="pl-0 mb-0 pt-2 list-unstyled text-start" [innerHTML]="feature">

        </ul>
      </div>
    </div>
  `,
  styles: [`
    .plans {
      border-radius: 10px;
    }
    .plans h2,.plans ul li,.plans p{
      font-family: "Poppins", Helvetica, sans-serif !important;
    }
  `],
})
export class PlanFeaturedMobileComponent {
  @Input() title: string;
  @Input() price: string;
  @Input() feature: string;

  constructor() {}
}
