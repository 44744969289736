import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'lib-jumbotron',
  template: `

  <div class="jumbotron text-white text-start">
    <div class="container">
      <div class="row d-flex">
        <div class="col-md-6 col-lg-6">
          <h1 class="mb-3">
            <span hidden>Credit Builder +
              <br></span>
            The Sustainable Way To Build
            <br />
            Your Credit Score
          </h1>
          <h1 class="sub">
            Unlimited
            <br />
            Texts & Minutes with all packages
            <br>
            On Our Guaranteed 12 Month Contract.
          </h1>
          <h6 class="mt-4">  <b>No credit check needed.</b></h6>
            <h6>  <b>Sign up in under 2 minutes.</b></h6>
          <div class=" mt-4 mb-3 ">
            <a  (click)="navigate('/create-account')">
              <img
                alt="build my credit score button"
                src="/assets/images/build-my-credit-score-now-button.png"
                class="col-8 pe-auto"
                style="max-width: 230px"
              />
            </a>
          </div>
          <div class="d-none d-lg-block">
            <p style="font-size: 12px !important;"><i>Improvements to your credit score are not guaranteed.</i></p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="text-center hero_img">
            <img
              alt="Credit score on phone"
              src="/assets/images/phone.png"
              class="col-8"

            />
          </div>
        </div>
      </div>
    </div>
  </div>
    <hr class="hr-brand mt-1 mb-0" hidden />
    <div class="container" hidden>
      <div class="text-center ">
        <a (click)="navigate('/credit-builder-plus')">
          <img
            alt="credit builder plus"
            src="/assets/images/credit-builder-plus.png"
            class="ml-5 mr-5 mt-2 mb-1 col-5"
            style="max-width: 300px"
          />
        </a>
      </div>
    </div>
    <hr class="hr-brand mt-2 mb-5" />
  `,
  styles: [`
    a:hover{
      cursor:pointer;
    }
    .jumbotron {
      background-image: url('/assets/images/background.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 1.5rem 1rem 2rem;
      width: 100%;
    }

  `]
})
export class JumbotronComponent {

  constructor(private router: Router) {}

  public navigate (route: string): void {
    console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
}
