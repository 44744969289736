import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'content-code-of-practice',
  templateUrl: './code-of-practice.component.html',
  styles: [],
})
export class CodeOfPracticeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
