<div class="content-page-container">
  <h2>Calls, Data & Roaming Charges</h2>

  <h3>RLAH – What is this?</h3>

  <p>RLAH stands for Roam Like At Home.</p>

  <p>
    This means that when you’re in Zone 1 (EU/EEA), you can use your inclusive
    allowances at no extra charge and if you do happen to go outside your
    allowances, you pay the same as you would in the UK.
  </p>

  <h3>Zone 7 (restricted) – what does this mean?</h3>

  <p>
    Zone 7 is a restricted zone – this means we cannot guarantee that you will
    receive any services whilst in these countries.
  </p>

  <h3>Charges FAQs</h3>

  <ul>
    <li>
      What's the difference between International and Roaming?<br />
      International - when you’re in the UK and you’re calling/texting someone
      who is not in the UK.<br />
      Roaming - when you use your phone (calls, SMS or data) when you are not in
      the UK.
    </li>
    <li>
      Do I need to do anything to use my services whilst abroad?<br />
      It is always advisable to call in to customer services prior to
      travelling, just to ensure there are no bars on your account.
    </li>
    <li>
      What about receiving calls in the UK?<br />
      All incoming calls whilst you are in the UK are free.
    </li>
    <li>
      When will I be billed?
      <br />
      Typically, you’d be billed on your next invoice, however, it can take up
      to 3 months to be billed for any international or roaming charges.
    </li>
    <li>
      I’m getting no service whilst abroad, what should I do?
      <br />
      Usually your handset will select a network automatically; however, this
      isn’t always the case. If you are not getting any service, try manually
      selecting a network on the setting of your device.
    </li>
    <li>
      If the above doesn’t work, you will need to contact customer services at
      hello@boshhh.com.
    </li>
  </ul>

  <h3>Credit Allowance</h3>

  <p>
    You will receive a credit allowance of up to £100 per calendar month (the
    “Credit Allowance”) which can be applied to your account via the ‘My Mobile’
    portal. The Credit Allowance may be used for add ons such as additional
    data, data roaming, international calls and premium texts. Please see our
    Terms & Conditions of Prepay Mobile Services [INSERT LINK HERE]. For more
    information please contact us at hello@boshhh.com.
  </p>

  <h3>Roaming Zones:</h3>

  <p>
    Zone 1: Austria, Azores, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic,
    Canary Islands, Denmark, Estonia, Finland, France, French Guyana, Germany,
    Gibraltar, Greece, Guadeloupe, Guernsey, Hungary, Iceland, Ireland, Isle of
    Man, Italy, Jersey, Latvia, Liechtenstein, Lithuania, Luxembourg, Madeira,
    Malta, Martinique, Mayotte, Monaco, Netherlands, Norway, Poland, Portugal,
    Reunion Islands, Romania, San Marino, Saint Martin (French), Saint
    Barthelemy, Slovakia, Slovenia, Spain, Sweden, Switzerland and Vatican City
    (Italy).
  </p>

  <p>Zone 2: Aland Islands, Ceuta and Melilla, USA.</p>

  <p>Zone 3: Australia, Canada, China, Israel.</p>

  <p>
    Zone 4: Anguilla, Antigua and Barbuda, Barbados, Brazil, Cayman Islands,
    Colombia, Côte D'Ivoire, Cyprus (Turkish), Egypt, Grenada, India,
    Madagascar, Malaysia, Moldova, Philippines, Saint Lucia, Saint Vincent and
    the Grenadines, Senegal, Singapore, Thailand, Turkey, Turks and Caicos
    Islands.
  </p>

  <p>
    Zone 5: Albania, Andorra, Botswana, Congo (DR), Greenland, Indonesia, Korea
    (South), Mexico, Pakistan, South Africa, Sri Lanka, Uganda, Zambia.
  </p>

  <p>
    Zone 6: Abkhazia, Afghanistan, American Samoa, Antarctica, Argentina,
    Armenia, Aruba, Ascension Island, Bahamas, Bahrain, Bangladesh, Belarus,
    Belize, Benin, Bermuda, Bhutan, Bolivia, Bosnia and Herzegovina, British
    Indian Ocean Territory, Brunei Darussalam, Burkina Faso, Burundi, Cambodia,
    Cameroon, Cape Verde, Caribbean Netherlands, Central African Republic, Chad,
    Christmas Island, Cocos Keeling Island, Comoros, Congo-Brazzaville, Cook
    Islands, Costa Rica, Cuba, Curacao, Diego Garcia, Djibouti, Dominica,
    Dominican Republic, Ecuador, El Salvador, Equatorial Guinea, Eritrea,
    Ethiopia, Falkland Islands, Faroe Islands, Fiji, French Polynesia, French
    Southern and Antarctic Lands, Gabon, Gambia, Georgia, Ghana, Guam,
    Guatemala, Guinea, Guinea-Bissau, Guyana, Haiti, Honduras, Hong Kong, Iran,
    Iraq, Japan, Jordan, Kazakhstan, Kenya, Kiribati, Korea (North), Kosovo,
    Kuwait, Kyrgyzstan, Laos, Lesotho, Libya, Macau, Macedonia, Malawi,
    Maldives, Mali, Marshall Islands, Mauritania, Mauritius, Micronesia,
    Mongolia, Montenegro, Montserrat, Morocco, Mozambique, Myanmar,
    Nagorno-Karabakh, Namibia, Nauru, Nepal, New Caledonia, New Zealand,
    Nicaragua, Niger, Nigeria, Niue, Norfolk Island, Northern Mariana Islands,
    Palau, Palestine, Panama, Papua New Guinea, Paraguay, Pitcairn Islands,
    Puerto Rico, Qatar, Russia, Rwanda, Saint Helena, Saint Kitts and Nevis,
    Saint Pierre and Miquelon, Samoa, São Tomé and Príncipe, Saudi Arabia,
    Serbia, Seychelles, Sint Maarten (Dutch), Solomon Islands, Somalia,
    Somaliland, South Ossetia, South Sudan, Sudan, Suriname, Svalbard and Jan
    Mayen, Swaziland, Syria, Tanzania, Taiwan, Tajikistan, Timor-Leste, Togo,
    Tokelau, Tonga, Transnistria, Trinidad and Tobago, Tristan da Cunha,
    Tunisia, Turkmenistan, Tuvalu, United States Minor Outlying Islands,
    Uruguay, Vanuatu, Venezuela, Vietnam, Virgin Islands (British), Virgin
    Islands (U.S.), Western Sahara, Yemen, Zimbabwe.
  </p>

  <p>
    Zone 7: Algeria, Angola, Azerbaijan, Chile, Jamaica, Lebanon, Liberia, Oman,
    Peru, Sierra Leone, Ukraine, United Arab Emirates, Uzbekistan, Wallis and
    Futuna Islands.
  </p>

  <h2>Calls, Data & Roaming Charges</h2>

  <p>UK Out of Bundle Charges (when you are in the UK)</p>

  <div class="row">
    <div class="col">
      <a
        href="assets/images/calls-data-roaming-charges-table.png"
        target="_blank"
      >
        <img
          src="assets/images/calls-data-roaming-charges-table.png"
          class="col-12"
          style="max-width: 900px"
        />
      </a>
    </div>
  </div>
</div>
