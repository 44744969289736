import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective } from "@angular/forms";

@Component({
  selector: 'lib-customer-details',
  template: `
    <form [formGroup]="customerDetailFormGroup">
      <mat-button-toggle-group appearance="standard" formControlName="title" class="mb-4 mt-3 col-12" required>
        <mat-button-toggle [value]="'Mr'" style="width:100%">Mr</mat-button-toggle>
        <mat-button-toggle [value]="'Mrs'" style="width:100%">Mrs</mat-button-toggle>
        <mat-button-toggle [value]="'Miss'" style="width:100%">Miss</mat-button-toggle>
        <mat-button-toggle [value]="'Ms'" style="width:100%">Ms</mat-button-toggle>
      </mat-button-toggle-group>
      <mat-form-field appearance="outline">
        <mat-label>First name:</mat-label>
        <input matInput formControlName="firstName" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Last name:</mat-label>
        <input matInput formControlName="lastName" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Date of birth:</mat-label>
        <input type="tel" matInput [textMask]="{mask: dateMask}" placeholder="DD/MM/YYYY" formControlName="dateOfBirth">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Telephone:</mat-label>
        <input type="text" matInput formControlName="telephone" pattern="\\d*" maxlength="11" minlength="10" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email address:</mat-label>
        <input type="email" matInput formControlName="emailAddress" required>
      </mat-form-field>
    </form>
  `,
  styles: [`
    .mat-button-toggle-checked {
      background-color: #009245 !important;
      color: white;
    }
  `]
})
export class CustomerDetailsComponent implements OnInit {

  @Input() formGroupName: string;
  dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  customerDetailFormGroup: FormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.customerDetailFormGroup =
      this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  causeValidation(): void {
    Object.keys(this.customerDetailFormGroup.controls).forEach( field => {
      const control = this.customerDetailFormGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      }
    });
  }
}
