import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { IPipedriveCustomerDetailModel } from "../models/pipedrive-customer-detail.model";
import { IPayment } from "../models/payment.model";

@Injectable({
  providedIn: 'root'
})
export class PipedriveService {

  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }

  public customerDetails(body: IPipedriveCustomerDetailModel): Observable<any> {
    return this
      .http
      .post<IPipedriveCustomerDetailModel>(`${this.env.pipedriveApi}/api/customer-details`,
        body,
        this.httpOptions
      );
  }

  public paymentMade(emailAddress: string): Observable<any> {
    return this
      .http
      .post<IPipedriveCustomerDetailModel>(`${this.env.pipedriveApi}/api/payment-made?emailAddress=${emailAddress}`,
        '',
        this.httpOptions
      );
  }

  public paymentDeclined(emailAddress: string): Observable<any> {
    return this
      .http
      .post<IPipedriveCustomerDetailModel>(`${this.env.pipedriveApi}/api/payment-declined?emailAddress=${emailAddress}`,
        '',
        this.httpOptions
      );
  }

  public callPhp(body: IPayment): Observable<any> {

    // this.downloadPDF();
    /*return this
      .http.post<IPayment>('https://dev.creditize.co.uk/payment-pdf.php', body, this.httpOptions);*/
    var mediaType = 'application/pdf';
    return this.http.post<IPayment>('https://gopgos.com/bosh/contract/contract-pdf.php', body, this.httpOptions)
  }

  public downloadPDF(): any {
    var mediaType = 'application/pdf';
    this.http.post('https://dev.creditize.co.uk/contract-pdf.php', {location: "report.pdf"}, { responseType: 'blob' }).subscribe(
      (response) => {
        var blob = new Blob([response], { type: mediaType });
        // saveAs(blob, 'report.pdf');
        console.log('blob', blob);
      },
      e => { throwError(e); }
    );
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };
}
