import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-create-account',
  template: `
    <div class="container">
      <div class="content-page-container">
        <h2>Let's Get Started Building Your Credit Score</h2>
      </div>
      <lib-account-stepper
        [preSelectedPackageId]="preSelectedPackageId"></lib-account-stepper>
    </div>
  `,
})
export class CreateAccountComponent implements OnInit {

  preSelectedPackageId: string;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');
    if (id) {
      this.preSelectedPackageId = id;
    }
  }
}
