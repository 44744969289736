import { Component, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";

@Component({
  selector: 'app-root',
  template: `
    <lib-header [useHelloFooter]="true"></lib-header>
    <div *ngIf="displayFooter">
      <lib-sticky-footer></lib-sticky-footer>
    </div>
  `
})
export class AppComponent implements OnInit {
  title = 'hello.boshhh.com';

  public location = '' ;
  displayFooter: boolean = true;
  constructor(router:Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        console.log(event.url);
        if (event.url.startsWith('/create-account')) {
          this.displayFooter = false;
        } else {
          this.displayFooter = true;
        }
      }
    })
  };

  ngOnInit(): void {
    console.log(this.location);
  }
}
