import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";


@Component({
  selector: 'lib-sim-plans-selector',
  templateUrl: './sim-plans-selector.component.html'
})
export class SimPlansSelectorComponent implements OnInit {
  @Input() useEventEmitter: boolean;
  @Output() selectedPackage = new EventEmitter<string>();

  priceIdOne: string;
  priceIdTwo: string;
  priceIdThree: string;

  constructor(
    // @ts-ignore
    @Inject('env') private env
  ) {}

  ngOnInit() {
    this.priceIdOne = this.env.priceIdOne;
    this.priceIdTwo = this.env.priceIdTwo;
    this.priceIdThree = this.env.priceIdThree;
  }

  selectPackage(packageId: string): void {
    this.selectedPackage.emit(packageId);
  }
}
