import { Component, OnInit } from '@angular/core';
import { IFooterModel } from "../../../../../libs/boshhh/src/lib/components/footer/footer.model";
import { Router } from "@angular/router";

@Component({
  selector: 'app-header',
  template: `

  `
})
export class HeaderComponent implements OnInit {

  footerLinks: IFooterModel[] = [
    {name: 'Build my Credit Score', route: 'create-account'},
    {name: 'Activate SIM', route: 'http://boshhh.webasoft.co.uk/activate-sim.html', isExternal: true},
    {name: 'Login', route: 'http://boshhh.webasoft.co.uk/login.html', isExternal: true},
    {name: 'Credit Builder Plus', route: 'credit-builder-plus'},
    {name: 'About Us', route: 'about-us'},
    {name: 'Privacy Policy', route: 'privacy-policy'},
    {name: 'Cookie Policy', route: 'cookie-policy'},
    {name: 'Terms & Conditions', route: 'terms-and-conditions'}
  ]

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public navigate (route: string): void {
    console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }

}
