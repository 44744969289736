<div class="content-page-container">
  <div class="text-center mb-4">
    <img
      alt="credit builder plus"
      src="/assets/images/credit-builder-plus.png"
      class="col-7 col-lg-5"
    />
  </div>
  <p>Your credit score is important.</p>
  <p>
    Having a good credit score gives you access to things like credit cards,
    loans, mortgages and also saves you money on interest payments.
  </p>

  <p>
    So, would you agree it makes sense to have the best credit score possible?
  </p>
  <p>Then you need Credit Builder Plus™️.</p>
  <p>
    <img
      class="col-7 col-lg-4 float-end"
      src="assets/images/home-phone-with-credit-score-updated.png"
    />
    Credit Builder Plus™️ actively builds your credit score, granting you access
    to the best credit cards, loans and mortgages and interest rates available.
  </p>
  <p>
    You may be thinking of other credit building products that exist in the
    market, but what else do they give you in return?
  </p>
  <p>The short answer is, nothing.</p>
  <p>
    Unlike other products in the market, Credit Builder Plus™️ is the only
    credit building product giving you a free sim card with unlimited texts,
    minutes and data on it.
  </p>
  <p>
    You get two products for the price of one, and you can keep your current
    mobile number - sounds too good to be true right?
  </p>
  <p>
    Credit Builder Plus™️ goes even further for you, too, empowering a radical
    improvement in your credit score, because you will be offered a loan.
  </p>
  <p>
    Your loan is paid back through an affordable monthly subscription and your
    loan status will be updated immediately after your loan is paid back,
    proving to the credit reference agencies that you are a reliable player.
  </p>
  <p>
    Once this update is recorded, your credit score will make a dramatic
    improvement of up to 300 points.
  </p>
  <p>
    This is a life-changing difference to you if you have a low credit score,
    the difference between having the ability to buy a house or not.
  </p>
  <p>
    In addition to increased access to credit, cheaper interest and unlimited
    minutes, texts and data sim, you will also get access to a toolbox brimming
    with revolutionary solutions like a credit report and credit diary that put
    you back in control of your own destiny.
  </p>
  <p>
    So, now you know this is all available to you for the cost of breakfast at
    your local cafe, doesn’t it make sense to get your free SIM and start
    building your credit score now?
  </p>
  <p *ngIf="showButton">
    <a class="get-started-button" href="/create-account">Click here to get started</a>
  </p>
  <!--<img alt="Experian Equifax ClearScore" src="/assets/images/experian-equifax-clearscore.png" class="col-12 mb-4" />-->
</div>
