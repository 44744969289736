<mat-drawer-container class="example-container" fullscreen>

  <mat-drawer #drawer class="example-sidenav" mode="push">
    <mat-toolbar class="white" [style.width.px]="innerWidth">
      <mat-toolbar-row class="">
        <a role="button" (click)="navigate('/', true)" class="logo-a">
          <img src="/assets/images/logo-inverted.png" alt="" class="main-logo mt-3 mb-3">
        </a>
        <span class="example-spacer"></span>
        <a mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="drawer.toggle()">
          <img alt="Close" src="/assets/images/close.png" width="20" />
        </a>
      </mat-toolbar-row>
    </mat-toolbar>
    <div style="padding: 2rem;">
      <mat-form-field appearance="outline" >
        <mat-label class="text-gray-dark">Search help center</mat-label>
        <input matInput>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <ul class="no-bullets pl-0 mt-4 text-center">

        <li class="mb-4">
          <a class="text-decoration-none font-size-lg text-green" href="https://boshhh.mbills.co.uk/Activate">Activate SIM</a>
        </li>
        <li class="mb-4">
          <a class="text-decoration-none font-size-lg text-gray-dark" href="https://boshhh.mbills.co.uk/Login">Login</a>
        </li>
        <li *ngIf="!production" class="mb-4">
          <a class="text-decoration-none font-size-lg text-gray-dark" (click)="navigate('/mobiles', true)">Refurbished Phones</a>
        </li>
        <li class="mb-4" hidden>
          <a class="text-decoration-none font-size-lg text-cbp-blue-dark" role="button" (click)="navigate('/credit-builder-plus', true)">Credit Builder <b class="text-cbp-blue-light">+</b></a>
        </li>
        <li class="mb-4" hidden>
          <a class="text-decoration-none font-size-lg text-gray-dark" href="#">FAQ's</a>
        </li>
        <li class="mb-4">
          <a class="text-decoration-none font-size-lg text-gray-dark" role="button" role="button" (click)="navigate('/terms-and-conditions', true)">Terms & Conditions</a>
        </li>
      </ul>
      <p class="stay-connected text-black text-center">
        Stay connected...
      </p>
      <div class="text-center">
        <a href="https://www.instagram.com/boshhhuk/">
          <img alt="Instagram" src="assets/images/social-instagram.png" width="50px" class="mr-3 mb-3" />
        </a>
        <a href="https://www.facebook.com/people/Boshhh/100087822891371/">
          <img alt="Facebook" src="assets/images/social-facebook.png" width="50px" class="mb-3" />
        </a>
        <a href="https://www.youtube.com/channel/UC5pybccpBc08dsejUZriaWA">
          <img alt="YouTube" src="assets/images/social-youtube.png" width="50px" class="ml-3 mb-3" />
        </a>
        <a href="https://twitter.com/boshhhuk">
          <img alt="Twitter" src="assets/images/social-twitter.png" width="50px" class="ml-3 mb-3" />
        </a>
        <a href="https://www.tiktok.com/@boshhhuk">
          <img alt="TikTok" src="assets/images/social-tiktok.png" width="50px" class="ml-3 mb-3" />
        </a>
      </div>
      <p class="text-gray-dark text-center mt-2">
        <small>
          &copy; 2022 Boshhh Ltd. All rights reserved.
          <br>
          Registered in England and Wales. No. 13188665.
          <br>
          140 Lee Lane, Horwich, Bolton, England, BL6 7AF.
          <br>
          <a href="mailto:hello@boshhh.com">hello@boshhh.com</a>
        </small>
      </p>
    </div>

  </mat-drawer>
  <div class="example-sidenav-content">
    <div  style="position: fixed; width: 100%; top:0; z-index: 100; background-image: url('/assets/images/background-nav.png'); background-size: 100% 100%;">
      <mat-toolbar color="primary" >
        <mat-toolbar-row class="container">
          <a role="button" (click)="navigate('/', false)">
            <img src="/assets/images/logo.png" alt="" class="main-logo mt-3 mb-3">
          </a>
          <div class="d-none d-lg-block desktop-nav text-end float-end pl-5">
            <a href="https://boshhh.mbills.co.uk/Activate">Activate SIM</a>
            <a href="https://boshhh.mbills.co.uk/Login">Login</a>
            <a *ngIf="!production"  role="button" (click)="navigate('/mobiles', false)">Refurbished Phones</a>
            <a role="button" (click)="navigate('/credit-builder-plus', false)" hidden>Credit Builder Plus</a>
            <a href="#" hidden>FAQ's</a>
            <a role="button" (click)="navigate('/terms-and-conditions', false)">Terms &amp; Conditions</a>
          </div>

          <span class="example-spacer"></span>
          <a mat-icon-button class="example-icon d-block d-lg-none" aria-label="Example icon-button with menu icon"
             (click)="drawer.toggle()">
            <img src="/assets/images/burger.png" alt="hamburger menu" class="hamburger" width="25" />
          </a>

        </mat-toolbar-row>
      </mat-toolbar>
    </div>

    <div class="router-container pl-0 pr-0">
      <router-outlet></router-outlet>

      <div *ngIf="useHelloFooter">
        <lib-footer-hello-temp></lib-footer-hello-temp>
      </div>
      <div *ngIf="!useHelloFooter">
        <lib-footer-boshhh-temp></lib-footer-boshhh-temp>
      </div>
    </div>


  </div>

</mat-drawer-container>

