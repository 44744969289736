import { Component, Input, OnInit } from "@angular/core";
import { IFooterModel } from "../footer/footer.model";

@Component({
  selector: 'lib-side-drawer',
  template: `
    <ul *ngIf="links">
      <li *ngFor="let link of links">
        <a *ngIf="!link.isExternal" [routerLink]="link.route">{{link.name}}</a>
        <a *ngIf="link.isExternal" href="{{link.route}}">{{link.name}}</a>
      </li>
    </ul>
    <p *ngIf="copyright">{{copyright}}</p>
    <p *ngIf="company">{{company}}</p>
    <p *ngIf="address">{{address}}</p>
    <p *ngIf="emailAddress"><a href="mailto:{{emailAddress}}?subject=Hello Boshhh">{{emailAddress}}</a></p>
  `
})
export class SideDrawerComponent implements OnInit {

  @Input() links: IFooterModel[];
  @Input() copyright: string;
  @Input() company: string;
  @Input() address: string;
  @Input() emailAddress: string;

  constructor() {}

  ngOnInit(): void {}
}
