import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, FormGroupDirective } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { AddressLookupService } from "../../../services/address-lookup.service";
import { IAddressLookupResponse } from "./address-lookup-response.model";

@Component({
  selector: 'lib-address-details',
  template: `
    <form [formGroup]="addressDetailsFormGroup">
      <ng-template matStepLabel>Bash:</ng-template>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>Postcode:</mat-label>
            <input matInput formControlName="billingPostcode" maxlength="8" style="text-transform:uppercase" required>
          </mat-form-field>
        </div>
        <div class="col-6">
          <a
            mat-button
            color="primary"
            class="col-12 mt-1 pt-2 pb-2 btn-green"
            (click)="this.doPostcodeLookup()">Postcode Lookup</a>
        </div>
      </div>
      <div *ngIf="loading$ | async" class="mx-auto d-flex justify-content-center">
        <img src="https://boshhh.com/assets/images/boshh-dark-spinner.gif" alt="spinner" width="80px">
      </div>
      <a
        mat-button
        color="primary"
        class="col-12 mt-1 pt-2 pb-2 mb-5"
        *ngIf="!showAddressDetails" (click)="doEnterAddressManually()">Enter address manually</a>

        <div *ngIf="showAddressDetails">
          <div *ngIf="!manualStreet">


            <div class="row" >
              <div class="col-10"  >
                <mat-form-field  appearance="outline">
                  <mat-label>Address line 1:</mat-label>
                  <mat-select #mySelect  formControlName="billingStreet1">
                    <mat-option *ngFor="let a of addressLookupResponse.thoroughfares" [value]="a.name">{{a.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-2 pt-2">
                <a mat-icon-button color="primary" (click)="doEnterStreetManually()">
                  <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="edit"></mat-icon>
                </a>
              </div>
            </div>
          </div>

          <mat-form-field *ngIf="manualStreet" appearance="outline">
            <mat-label>Address line 1:</mat-label>
            <input matInput formControlName="billingStreet1">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Address line 2:</mat-label>
            <input matInput formControlName="locality">
          </mat-form-field >
          <mat-form-field appearance="outline">
            <mat-label>Town/City:</mat-label>
            <input matInput formControlName="billingCity">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>County:</mat-label>
            <input matInput formControlName="county">
          </mat-form-field>
        </div>


    </form>

  `,
  styles: [`
    .btn-green{
        background: -webkit-linear-gradient(45deg, #009245, #003f37) !important;
    width: 100% !important;
    }
    
  `]
  
})
export class AddressDetailsComponent implements OnInit {
  @ViewChild('mySelect') mySelect : any;
  @Input() formGroupName: string;
  @Input() countyRequired: boolean;
  addressDetailsFormGroup: FormGroup;
  showAddressDetails: boolean;
  manualStreet: boolean;

  addressLookupResponse: IAddressLookupResponse;

  public loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private fb: FormBuilder,
    private rootFormGroup: FormGroupDirective,
    private addressLookupService: AddressLookupService
  ) {}

  ngOnInit(): void {
    this.initialFormSetup();
    this.addressDetailsFormGroup =
      this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  initialFormSetup(): void {
    this.showAddressDetails = false;
    this.manualStreet = false;

    this.addressLookupResponse = {
      county: '',
      locality: '',
      town: '',
      postCode: '',
      thoroughfares: [{
        name: ''
      }]

    }
    this.addressLookupResponse.thoroughfares = [{
      name: ''
    }
    ];
  }

  doEnterAddressManually(): void {
    this.showAddressDetails = true;
    this.manualStreet = true;
  }

  doEnterStreetManually(): void {
    this.manualStreet = true;
    this.addressDetailsFormGroup.controls['billingStreet1'].setValue('');
  }

  doPostcodeLookup(): void {

    if (this.addressDetailsFormGroup.controls['billingPostcode'].value) {
      this.loading$.next(true);
      this.showAddressDetails = true;
      this.manualStreet = false;
      this.addressLookupService.post(this.addressDetailsFormGroup.controls['billingPostcode'].value)
        .subscribe(result => {
          this.addressLookupResponse = result;
          this.addressDetailsFormGroup.setValue({
            billingStreet1: '',
            billingPostcode: this.addressLookupResponse.postCode,
            locality: this.addressLookupResponse.locality,
            billingCity: this.addressLookupResponse.town,
            county: this.addressLookupResponse.county
          });

          this.showAddressDetails = true;
          this.mySelect.open();
        }, error => {}, () => {
          this.loading$.next(false);
          this.mySelect.open();
        });
    } else {
      this.addressDetailsFormGroup.controls['billingPostcode'].markAsTouched();
    }
  }
}
