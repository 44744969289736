import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'lib-sim-plans-selector-mobile',
  templateUrl: './sim-plans-selector-mobile.component.html',
  styles: [
    `
        .main-plan {
          border-radius: 10px;
        }
    `
  ]
})
export class SimPlansSelectorMobileComponent implements OnInit {
  @Input() useEventEmitter: boolean;
  @Output() selectedPackage = new EventEmitter<string>();

  priceIdOne: string;
  priceIdTwo: string;
  priceIdThree: string;

  constructor(
    // @ts-ignore
    @Inject('env') private env
  ) {}

  ngOnInit() {
    this.priceIdOne = this.env.priceIdOne;
    this.priceIdTwo = this.env.priceIdTwo;
    this.priceIdThree = this.env.priceIdThree;
  }

  selectPackage(packageId: string): void {
    this.selectedPackage.emit(packageId);
  }
}
