import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-center',
  template: `
    <div class="container">
      <p>help-center works!</p>
    </div>
  `
})
export class HelpCenterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
