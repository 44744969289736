import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-consent',
  template: `
    <p>cookie-consent works!</p>
  `
})
export class CookieConsentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
