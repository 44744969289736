import { Component, Input } from "@angular/core";

@Component({
  selector: 'lib-plan-standard-mobile',
  template: `
    <div class="row pl-3 pt-3 pr-0 pb-3">
      <div class="col-12">
      <h2 class="mb-0 lh-2 fw-bolder text-light" style="font-family: 'Poppins', Helvetica, sans-serif !important;" [innerHTML]="title"></h2>
        <img src="{{imagesPath}}" alt="Credit builder"  height="30px" hidden>
      </div>
    </div>
    <div class="row pl-3 pt-0 pr-0 pb-3">
      <div class="col-6 pt-2" >
        <div class="sim-card-container col-12">
          <img alt="SIM Card" src="/assets/images/sim-card-icon.png" class="col-12" />
        </div>
      </div>
      <div class="col-6 pl-0 pt-0 text-start">

        <h1 class="mb-0 text-white text-heading" [innerHTML]="price"></h1>
        <ul class="pl-0 mb-0 pt-0 list-unstyled text-start">
          <li class="mb-0 text-white"><strong>Unlimited</strong></li>
          <li class="mb-0 text-white"><small>Minutes</small></li>
          <li class="mb-0 text-white"><small>Texts</small></li>
        </ul>
      </div>
    </div>
  `,
  styles: [`
  .plans h2,.plans ul li,.plans p{
    font-family: "Poppins", Helvetica, sans-serif !important;
  }
  `]
})
export class PlanStandardMobileComponent {
  @Input() title: string;
  @Input() price: string;
  @Input() imagesPath: string;

  constructor() {}
}
