<div class="content-page-container">
  <h2>How to and FAQs</h2>

  <h3>How do I activate my SIM card?</h3>

  <ul>
    <li>
      After receiving the SIM please follow the instructions on your welcome
      letter.
    </li>
    <li>You can also use this guide below:</li>
    <li>Click on activate. </li>
    <li>Enter the following details:</li>
    <li>Full name</li>
    <li>Valid email address</li>
    <li>Phone number (located on SIM card & literature).</li>
    <li>Create a password</li>
    <li>Click ‘Activate’</li>
  </ul>

  <p>PLEASE NOTE: SIM cards can take up to 24 hours to activate.</p>

  <h3>How do I know if my SIM is active?</h3>

  <ul>
    <li>Once your SIM is activated, you will receive a confirmation email.</li>
    <li>
      Insert your SIM into a suitable device correctly and turn on Mobile data.
    </li>
  </ul>

  <h3>How do I turn on mobile data?</h3>

  <ul>
    <li>This will depend on the device you are using.</li>
    <li>Go to Google and type in:</li>
    <li>
      How to turn on the mobile data on my (enter make and model of your device)
    </li>
    <li>
      Once you have turned on Data Roaming (Your device should now pick up the
      signal automatically).
    </li>
    <li>
      If this is not the case (Enter APN settings for your SIM), see FAQs for
      this.
    </li>
  </ul>

  <h3>How can I contact you?</h3>

  <ul>
    <li>
      Click on HELP at the top or bottom of the page and select CONTACT US, you
      will find an easy to use form that will give us the information we need to
      help you.
    </li>
  </ul>

  <h3>How can I see my usage and balance?</h3>

  <ul>
    <li>
      Click on LOG-IN at the top or bottom of the page and enter your email or
      mobile number and the password which you had set up when you activated
      your account.
    </li>
    <li>
      If you have forgotten this click (Forgotten password) and a reset password
      link will be emailed to you.
    </li>
  </ul>

  <h3>How do I change things on my account?</h3>

  <ul>
    <li>
      Click on LOG-IN at the top or bottom of the page and enter your email or
      mobile number and the password which you had set up when you activated
      your account. Here you will find everything you need.
    </li>
  </ul>

  <h3>How do I add extras to my account?</h3>

  <ul>
    <li>
      Click on EXTRAS at the top or bottom of the page and select either BOLT ON
      or TOP UP to find what you are looking for.
    </li>
    <li>
      Alternatively you may just want a little more time. If this is the case
      click RENEW and add more time to your package.
    </li>
  </ul>

  <h3>How will I know when my allowance runs out?</h3>

  <ul>
    <li>
      Click on LOG-IN at the top or bottom of the page and enter your email or
      mobile number and the password which you had set up when you activated
      your account.
    </li>
    <li>
      At the top of the screen you will see your usage; if any of these are on
      “0” you are all out! Don’t worry, you can top-up from your account and it
      will be activated within minutes.
    </li>
  </ul>

  <h3>How do I add another month to my SIM?</h3>

  <ul>
    <li>
      Click on LOG-IN at the top or bottom of the page and enter your email or
      mobile number and the password which you had set up when you activated
      your account, then go to RENEW.
    </li>
    <li>
      Alternatively Click on RENEW at the top or bottom of the page and select
      the additional months you want.
    </li>
  </ul>

  <h3>How do I add more text messages to my SIM?</h3>

  <ul>
    <li>
      Click on LOG-IN at the top or bottom of the page and enter your email or
      mobile number and the which you had set up when you activated your
      account, then select either BOLT ON or TOP UP to find what you are looking
      for.
    </li>
    <li>
      Alternatively Click on BOLT ON or TOP UP at the top or bottom of the page
      and select the additional months messages you want.
    </li>
  </ul>

  <h3>How do I add more minutes to my SIM?</h3>

  <ul>
    <li>
      Click on LOG-IN at the top or bottom of the page and enter your email or
      mobile number and the which you had set up when you activated your
      account, then select either BOLT ON or TOP UP to find what you are looking
      for.
    </li>
    <li>
      Alternatively Click on BOLT ON or TOP UP at the top or bottom of the page
      and select the additional months minutes you want.
    </li>
  </ul>

  <h3>How do I add more data to my SIM?</h3>

  <ul>
    <li>
      Click on LOG-IN at the top or bottom of the page and enter your email or
      mobile number and the which you had set up when you activated your
      account, then select either BOLT ON or TOP UP to find what you are looking
      for.
    </li>
    <li>
      Alternatively Click on BOLT ON or TOP UP at the top or bottom of the page
      and select the additional months data you want.
    </li>
  </ul>

  <h3>How do I recommend you to others?</h3>

  <ul>
    <li>
      Click the link on the home page, in the APP or on your usage portal, put
      in the person's email address and your name and we will let them know it’s
      from you.
    </li>
  </ul>

  <h3>How can I complain about something?</h3>

  <ul>
    <li>
      We do everything we can to give every customer a hassle free experience,
      we also understand that sometimes things don’t always go the way they
      should!
    </li>
    <li>
      Click on HELP at the top or bottom of the page and select CONTACT US and
      you will find an easy to use form that will give us the information we
      need to help.
    </li>
    <li>Give us the opportunity to put it right.</li>
  </ul>

  <h3>How long do I have to activate my SIM?</h3>

  <ul>
    <li>
      Simply visit our activation page whenever you’re ready and your SIM will
      be ready to go.
    </li>
  </ul>

  <h2 class="text-dark">FAQs</h2>

  <h3>Is tethering and hotspot supported?</h3>

  <ul>
    <li>
      Yes, this is the beauty of our products which are simple and lets you
      enjoy the data with other devices.
    </li>
  </ul>

  <h3>What devices can I put my SIM card into?</h3>

  <ul>
    <li>
      SIMS can be used in any unlocked device, including mobiles, tablets,
      mobile wifi unit and routers.
    </li>
  </ul>

  <h3>At what point is my SIM activated?</h3>

  <ul>
    <li>
      Your SIM is active only after completing the activation section on
      sim-ple.co.uk. You will then receive an email and text confirming the
      activation.
    </li>
  </ul>

  <h3>Can I see usage balance?</h3>

  <ul>
    <li>
      Click on LOG-IN at the top or bottom of any page and enter your email or
      mobile number and the password which you set up when you activated your
      account. If you have forgotten this, click (Forgotten password) and a
      reset password link will be emailed to you.
    </li>
  </ul>

  <h3>Where can I use my SIM?</h3>

  <p>
    Albania, Anguilla, Antigua, Aruba, Australia, Austria, Balearic Islands,
    Barbados, Belgium, Bermuda, Bosnia & Herzegovina, Bulgaria, Canada, Canary
    Islands, Cayman Islands, Channel Islands, Croatia, Cyprus, Czech Republic,
    Denmark, Dominica, Estonia, Faroe Islands, Finland, France, French
    Territories, Germany, Ghana, Gibraltar, Greece, Grenada, Guernsey Hungary,
    Iceland, Ireland, Isle of Man, Italy, Jamaica, Jersey, Latvia, Kenya,
    Lesotho, Liechtenstein, Lithuania, Luxembourg, Madeira, Malta, Mexico,
    Monaco, Montserrat, Mozambique, Netherlands, Netherland Antilles, New
    Zealand, Norway, Poland, Portugal, Romania, San Marino ,Slovakia, Slovenia,
    South Africa, Spain, St Kitts and Nevis, St Vincent, St Lucia, Sweden,
    Switzerland, Trinidad & Tobago, Turkey, Turks and Caicos, US Virgin Islands,
    USA, Vatican City.
  </p>

  <h3>What do I do if my SIM doesn’t connect automatically?</h3>

  <p>
    Upon activation, most devices will locate the network automatically.
    However, if you are having trouble accessing the internet please check your
    APN settings.
  </p>

  <h3>APN Settings Android</h3>

  <ol>
    <li>Open Settings on the home screen</li>
    <li>Tap Connections</li>
    <li>
      Tap Wireless and Networks or More (this will depend on your Android
      device)
    </li>
    <li>Tap Mobile Networks > Access Point Names</li>
    <li>
      Tap the Menu button (this could be the lower left soft key, 3 dots in the
      top right corner, or the word ‘more’
    </li>
    <li>
      Tap New APN
      <br />
      EE
      <br />
      APN: everywhere
      <br />
      Username: eesecure
      <br />
      Password: secure
    </li>
  </ol>

  <h3>Type the following:</h3>

  <ol>
    <li>Tap Menu > Save</li>
    <li>Choose the Name we have just created</li>
    <li>
      Next to the signal bars, you’ll now see 4G/ 3G/ H/ E or G. This shows
      you’re now connected
    </li>
  </ol>

  <h3>APN Settings iPhone</h3>

  <ol>
    <li>Tap Settings > Mobile/Cellular Data.</li>
    <li>Tap Mobile/Cellular Data Options.</li>
    <li>Tap Mobile/Cellular Data Network.</li>
    <li>
      Enter the information below:
      <br />
      EE
      <br />
      APN: everywhere
      <br />
      Username: eesecure
      <br />
      Password: secure
    </li>
  </ol>

  <h3>Type the following:</h3>

  <ol>
    <li>Press the left arrow at the top of the screen 3 times.</li>
    <li>Press the Home button.</li>
    <li>
      To make sure these settings have worked, look next to your signal bars.
      4G/ 3G/ E or GPRS shows you’re now connected
    </li>
  </ol>
</div>
