<div class="content-page-container">
  <h2>Call Recording Policy</h2>

  <h3>Introduction</h3>

  <p>
    Boshhh Ltd has a telephone system that is capable of recording
    conversations. Like many other organisations, this is a standard practice
    that allows the recording of telephone calls for quality monitoring,
    training, compliance and security purposes.
  </p>

  <p>
    All calls received into Boshhh Ltd will be recorded and will be retained for
    a limited period of time, after which they will be deleted unless retained
    for the purposes specified in this policy.
  </p>

  <p>
    Recordings will only be used for the purposes specified in this policy.
    There is a recorded message in place to inform callers that their call is
    being recorded. If the call comes into a non-call recorded line and is then
    transferred to a call recorded line, the staff member should notify the
    caller that their call will be recorded.
  </p>

  <h3>Purpose of this Call Recording Policy</h3>

  <p>
    In order to maintain high standards and protect the public and staff, we
    need to record all telephone calls received into each of our call centres
    and retain them for a limited period of time. We shall ensure that the use
    of these recordings is fair and that we comply with the requirements of the
    relevant legislation.
  </p>

  <h3>This includes:</h3>

  <ul>
    <li>The Regulation of Investigatory Powers Act 2000</li>
    <li>
      The Telecommunications (Lawful Business Practice) (Interception of
      Communications Regulations) 2000
    </li>
    <li>
      The Telecommunications (Data Protection and Privacy) Regulations 1999
    </li>
    <li>The General Data Protection Regulation 2018</li>
    <li>The Data Protection Act 2018</li>
    <li>The Human Rights Act 1998.</li>
  </ul>

  <h3>Scope of policy</h3>

  <p>
    All calls made to Boshhh Ltd will be recorded. Under normal circumstances, a
    call will not be retrieved or monitored unless:
  </p>

  <ul>
    <li>it is necessary to investigate a complaint;</li>
    <li>
      it is a part of a management 'spot check' that customer service standards
      are being met;
    </li>
    <li>
      there is a threat to the health and safety of staff, visitors or for the
      prevention or detection of crime;
    </li>
    <li>it is necessary to check compliance with regulatory procedures; or</li>
    <li>
      it will aid standards in call handling through use in training of our
      staff.
    </li>
  </ul>

  <h3>Collecting information</h3>

  <p>
    Personal data collected in the course of recording activities will be
    processed fairly and lawfully in accordance with data protection law. It
    will be:
  </p>

  <ul>
    <li>adequate, relevant and not excessive;</li>
    <li>
      used for the purpose(s) stated in this policy only and not used for any
      other purposes;
    </li>
    <li>
      accessible only to managerial staff after securing permission from the
      Managing Director;
    </li>
    <li>treated confidentially;</li>
    <li>stored securely;</li>
    <li>
      not kept for longer than necessary and will be securely destroyed once the
      issue(s) in question have been resolved; and
    </li>
    <li>
      where payment details are collected over the phone, the member of staff
      will stop recording and restart once these details have been taken.
    </li>
  </ul>

  <h3>Advising callers that calls are being recorded and monitored</h3>

  <p>
    Where call recording facilities are being used we will inform the caller
    that their call is being monitored/recorded for quality/training purposes.
    This is so that they have the opportunity to consent by continuing with the
    call or hanging up. We will publish our policy on our website.
  </p>

  <h3>Procedures for managing and releasing call recordings</h3>

  <p>
    The recordings shall be stored securely, with access controlled and managed
    by one individual. Call recordings will be deleted after a limited time.
    Access to call recordings will be given to Managers and Directors for the
    purposes listed above. Browsing of recordings for no valid reason is not
    permitted. Every individual has the right to access the information that we
    hold about them. This includes recorded telephone calls. Therefore, the
    recordings will be stored in such a way to enable the retrieval of
    information relating to one or more individuals as easily as possible.
  </p>
</div>
