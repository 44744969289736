import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'content-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styles: [],
})
export class TermsAndConditionsComponent implements OnInit {
  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {}

  public navigate (route: string): void {
    console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
}
