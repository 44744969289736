// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentConfiguration: 'DEVELOPMENT',
  addressLookupApi: 'https://func-boshhh-addresslookup-dev-001.azurewebsites.net',
  mbillsApi: 'https://func-boshhh-mbills-dev-001.azurewebsites.net',
  pipedriveApi: 'https://func-boshhh-pipedrive-dev-001.azurewebsites.net',
  stripeApi: 'https://func-boshhh-stripe-dev-001.azurewebsites.net',
  termsApi: 'https://func-boshhh-terms-dev-001.azurewebsites.net',
  priceIdOne: 'price_1M1ULfE8CpEsx2nlYuHFJwBj',
  priceIdTwo: 'price_1M2sHlE8CpEsx2nlO5BbWQBA',
  priceIdThree: 'price_1M2sKjE8CpEsx2nlO6r1WELh',
  initialSetupFee: 7.99,
  stripePublishableKey: 'pk_test_51LzeVtE8CpEsx2nlxBP5DH5tCqofm4Gf1L05NdXbtHq1oQJWmbtYMIDEcNl2xAosHAVwq2wT2eHrWZ3IlKnx4doU00FmpfiRXs',
  pdfMonkeyToken: 'Bearer tjC9v7vJehCBPweHUxCxDCkhoke-yY9y'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
