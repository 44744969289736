<div class="text-center">
  <h1 class="mb-4">
    SIM Plans
  </h1>
</div>

<div class="container">
  <div class="row">
    <div class="col-sm-6">
      <div  class="plans main-plan mb-3">

        <!-- START PACKAGE 1 -->
        <div *ngIf="!useEventEmitter">
          <a href="/create-account/{{priceIdOne}}" class="text-decoration-none text-white">
            <lib-plan-featured-mobile
              title="10GB <small class='fw-normal'>Data<small>"
              price="£22<small>.99</small>"
              feature="
              <li>Unlimited Minutes</li>
              <li>Unlimited Texts</li>
              <li>10GB Data</li>
              <li>Credit Builder Plus</li>"></lib-plan-featured-mobile>
          </a>
        </div>
        <div *ngIf="useEventEmitter">
          <a (click)="selectPackage(priceIdOne)" class="text-decoration-none text-white">
            <lib-plan-featured-mobile
              title="10GB <small class='fw-normal'>Data<small>"
              price="£22<small>.99</small>"
              feature="
              <li>Unlimited Minutes</li>
              <li>Unlimited Texts</li>
              <li>10GB Data</li>
              <li>Credit Builder Plus</li>"></lib-plan-featured-mobile>
          </a>
        </div>
        <!-- START PACKAGE 1 -->

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6 pt-0">
      <div class="plans green mb-3">

        <!-- START PACKAGE 2 -->
        <div *ngIf="!useEventEmitter">
          <a href="/create-account/{{priceIdTwo}}" class="text-decoration-none text-black">

            <lib-plan-standard-mobile
              title="30GB <small class='fw-normal'>Data<small>"
              price="£29<small>.99</small>"
              imagesPath="https://dev.boshhh.com/assets/images/credit-builder-plus-logo-2.png"></lib-plan-standard-mobile>

          </a>
        </div>
        <div *ngIf="useEventEmitter">
          <a (click)="selectPackage(priceIdTwo)" class="text-decoration-none text-black">

            <lib-plan-standard-mobile
              title="30GB <small class='fw-normal'>Data<small>"
              price="£29<small>.99</small>"
              imagesPath="https://dev.boshhh.com/assets/images/credit-builder-plus-logo-2.png"></lib-plan-standard-mobile>

          </a>
        </div>
        <!-- END PACKAGE 2 -->

      </div>
    </div>
    <div class="col-6 pt-0">
      <div class="plans pink mb-3">
        <!-- START PACKAGE 3 -->
        <div *ngIf="!useEventEmitter">
          <a href="/create-account/{{priceIdThree}}" class="text-decoration-none text-black">

            <lib-plan-standard-mobile
              title="3GB <small class='fw-normal'>Data<small>"
              price="£15<small>.99</small>"
              imagesPath="https://dev.boshhh.com/assets/images/credit-builder-logo-2.png"></lib-plan-standard-mobile>

          </a>
        </div>
        <div *ngIf="useEventEmitter">
          <a (click)="selectPackage(priceIdThree)" class="text-decoration-none text-black">

            <lib-plan-standard-mobile
              title="3GB <small class='fw-normal'>Data<small>"
              price="£15<small>.99</small>"
              imagesPath="https://dev.boshhh.com/assets/images/credit-builder-logo-2.png"></lib-plan-standard-mobile>

          </a>
        </div>
        <!-- END PACKAGE 3 -->

      </div>
    </div>
  </div>
</div>

