import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  template: `
    <p>about-us works!</p>
    <content-about-us></content-about-us>
  `
})
export class AboutUsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
