import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StickyFooterComponent } from './components/sticky-footer/sticky-footer.component';
import { TermsAndConditionsComponent } from './content/terms-and-conditions/terms-and-conditions.component';
import { CookiePolicyComponent } from './content/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './content/privacy-policy/privacy-policy.component';
import { CallRecordPolicyComponent } from './content/call-record-policy/call-record-policy.component';
import { CallsDataRoamingChargesComponent } from './content/calls-data-roaming-charges/calls-data-roaming-charges.component';
import { CodeOfPracticeComponent } from './content/code-of-practice/code-of-practice.component';
import { HowToAndFaqsComponent } from './content/how-to-and-faqs/how-to-and-faqs.component';
import { RefundPolicyComponent } from './content/refund-policy/refund-policy.component';
import { AboutUsComponent } from './content/about-us/about-us.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterLinkWithHref, RouterOutlet } from '@angular/router';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { HowItWorksComponent } from './content/how-it-works/how-it-works.component';
import { FrequentlyAskedQuestionsComponent } from './content/frequently-asked-questions/frequently-asked-questions.component';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';
import { SideDrawerComponent } from './components/side-drawer/side-drawer.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AccountStepperComponent } from './components/account-stepper/account-stepper.component';
import { SelectPackageComponent } from './components/select-package/select-package.component';
import { MatStepperModule } from '@angular/material/stepper';
import { CustomerDetailsComponent } from './components/account-stepper/customer-details/customer-details.component';
import { PackageDetailsComponent } from './components/account-stepper/package-details/package-details.component';
import { AddressDetailsComponent } from './components/account-stepper/address-details/address-details.component';
import { CardDetailsComponent } from './components/account-stepper/card-details/card-details.component';
import { CompleteComponent } from './components/account-stepper/complete/complete.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TextMaskModule } from 'angular2-text-mask';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { HeaderComponent } from './components/header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SimPlansSelectorComponent } from './components/sim-plans-selector/sim-plans-selector.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FooterBoshhhTempComponent } from './components/footer/footer-boshhh-temp.component';
import { FooterHelloTempComponent } from './components/footer/footer-hello-temp.component';
import { PlanFeaturedComponent } from './components/sim-plans-selector/plan-featured.component';
import { PlanStandardComponent } from './components/sim-plans-selector/plan-standard.component';
import { StripePaymentDetailsComponent } from './components/account-stepper/stripe-payment-details/stripe-payment-details.component';
import { NgxStripeModule } from 'ngx-stripe';
import { BoshhhStripeService } from './services/boshhh-stripe.service';
import { AddressLookupService } from './services/address-lookup.service';
import { MbillsService } from './services/mbills.service';
import { SignedAgreementComponent } from './components/account-stepper/signed-agreement/signed-agreement.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { SimPlansSelectorMobileComponent } from './components/sim-plans-selector-mobile/sim-plans-selector-mobile.component';
import { PlanFeaturedMobileComponent } from './components/sim-plans-selector-mobile/plan-featured-mobile.component';
import { PlanStandardMobileComponent } from './components/sim-plans-selector-mobile/plan-standard-mobile.component';
import { SelectedPackageComponent } from './components/account-stepper/selected-package/selected-package.component';
import { ContentCreditBuilderPlusComponent } from './content/credit-builder-plus/content-credit-builder-plus.component';
import { ReturnsPolicyComponent } from './content/returns-policy/returns-policy.component';

@NgModule({
  imports: [
    CommonModule,
    RouterLinkWithHref,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    TextMaskModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    RouterOutlet,
    MatButtonModule,
    MatExpansionModule,
    NgxStripeModule,
    MatCheckboxModule,
    MatCardModule,
  ],
  declarations: [
    HeaderComponent,
    StickyFooterComponent,
    TermsAndConditionsComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    CallRecordPolicyComponent,
    CallsDataRoamingChargesComponent,
    CodeOfPracticeComponent,
    HowToAndFaqsComponent,
    RefundPolicyComponent,
    AboutUsComponent,
    FooterComponent,
    JumbotronComponent,
    HowItWorksComponent,
    FrequentlyAskedQuestionsComponent,
    CookieConsentComponent,
    SideDrawerComponent,
    AccountStepperComponent,
    SelectPackageComponent,
    CustomerDetailsComponent,
    PackageDetailsComponent,
    AddressDetailsComponent,
    CardDetailsComponent,
    CompleteComponent,
    SimPlansSelectorComponent,
    FooterBoshhhTempComponent,
    FooterHelloTempComponent,
    PlanFeaturedComponent,
    PlanStandardComponent,
    PlanFeaturedMobileComponent,
    PlanStandardMobileComponent,
    StripePaymentDetailsComponent,
    SignedAgreementComponent,
    SimPlansSelectorMobileComponent,
    SelectedPackageComponent,
    ContentCreditBuilderPlusComponent,
    ReturnsPolicyComponent,
  ],
  exports: [
    StickyFooterComponent,
    TermsAndConditionsComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    CallRecordPolicyComponent,
    CallsDataRoamingChargesComponent,
    CodeOfPracticeComponent,
    HowToAndFaqsComponent,
    RefundPolicyComponent,
    AboutUsComponent,
    ContentCreditBuilderPlusComponent,
    FooterComponent,
    JumbotronComponent,
    HowItWorksComponent,
    FrequentlyAskedQuestionsComponent,
    CookieConsentComponent,
    SideDrawerComponent,
    AccountStepperComponent,
    SelectPackageComponent,
    HeaderComponent,
    SimPlansSelectorComponent,
    SimPlansSelectorMobileComponent,
    ReturnsPolicyComponent
  ]
})
export class BoshhhModule {
  public static forRoute(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: BoshhhModule,
      providers: [
        BoshhhStripeService,
        {
          provide: 'env',
          useValue: environment,
        },
        AddressLookupService,
        {
          provide: 'env',
          useValue: environment,
        },
        MbillsService,
        {
          provide: 'env',
          useValue: environment,
        },
      ],
    };
  }
}
