import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-record-policy',
  template: `
    <content-call-record-policy></content-call-record-policy>
  `
})
export class CallRecordPolicyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
