import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormGroupDirective } from "@angular/forms";

@Component({
  selector: 'lib-card-details',
  template: `
    <form [formGroup]="cardDetailsFormGroup">
      <ng-template matStepLabel>Boshhh:</ng-template>
      <mat-form-field appearance="outline">
        <mat-label>Date of birth:</mat-label>
        <input type="tel" matInput [textMask]="{mask: dateMask}" placeholder="DD/MM/YYYY" formControlName="dateOfBirth">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Card holder:</mat-label>
        <input matInput formControlName="cardHolder">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Card number:</mat-label>
        <input type="tel" matInput [textMask]="{mask: cardMask}" formControlName="cardNumber">
      </mat-form-field>
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>Expiry month:</mat-label>
            <mat-select  formControlName="expiryMonth">
              <mat-option [value]="'01'">01</mat-option>
              <mat-option [value]="'01'">02</mat-option>
              <mat-option [value]="'01'">03</mat-option>
              <mat-option [value]="'01'">04</mat-option>
              <mat-option [value]="'01'">05</mat-option>
              <mat-option [value]="'01'">06</mat-option>
              <mat-option [value]="'01'">07</mat-option>
              <mat-option [value]="'01'">08</mat-option>
              <mat-option [value]="'01'">09</mat-option>
              <mat-option [value]="'01'">10</mat-option>
              <mat-option [value]="'01'">11</mat-option>
              <mat-option [value]="'01'">12</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="outline">
            <mat-label>Expiry year:</mat-label>
            <mat-select  formControlName="expiryYear">
              <mat-option [value]="'01'">22</mat-option>
              <mat-option [value]="'01'">23</mat-option>
              <mat-option [value]="'01'">24</mat-option>
              <mat-option [value]="'01'">25</mat-option>
              <mat-option [value]="'01'">26</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>CVV:</mat-label>
        <input type="text" matInput formControlName="cvv" pattern="\\d*" maxlength="4">
      </mat-form-field>
    </form>
  `
})
export class CardDetailsComponent implements OnInit {

  @Input() formGroupName: string;
  cardMask = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]
  dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  cardDetailsFormGroup: FormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.cardDetailsFormGroup =
      this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  causeValidation(): void {
    Object.keys(this.cardDetailsFormGroup.controls).forEach( field => {
      const control = this.cardDetailsFormGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      }
    });
  }
}
