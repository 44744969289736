import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-to-and-faqs',
  template: `
    <div class="container">
      <content-how-to-and-faqs></content-how-to-and-faqs>
    </div>
  `
})
export class HowToAndFaqsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
