import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'content-calls-data-roaming-charges',
  templateUrl: './calls-data-roaming-charges.component.html',
  styles: [],
})
export class CallsDataRoamingChargesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
