import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BoshhhStripeService {

  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }

  public config(): Observable<any> {
    console.log(this.env.environmentConfiguration);
    return this
      .http
      .get<any>(`${this.env.stripeApi}/api/config`,
        this.httpOptions
      );
  }

  public createCustomer(customerEmail: string, customerPhone: string, customerName: string): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.stripeApi}/api/create-customer?customerEmail=${customerEmail}&customerPhone=${customerPhone}&customerName=${customerName}`,
        '',
        this.httpOptions
      );
  }

  public createSubscription(customerId: string, priceId: string): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.stripeApi}/api/create-subscription?customerId=${customerId}&priceId=${priceId}`,
        '',
        this.httpOptions
      );
  }

  public subscriptions(customerId: string): Observable<any> {
    return this
      .http
      .post<any>(`${this.env.stripeApi}/api/subscriptions?customerId=${customerId}`,
        '',
        this.httpOptions
      );
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };
}
