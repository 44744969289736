import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-code-of-practice',
  template: `
    <content-code-of-practice></content-code-of-practice>
  `
})
export class CodeOfPracticeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
