import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'lib-select-package',
  template: `<div *ngIf="showCase">
      Showcase Package
      <br>
      Data: {{dataPackage}
      <br>
      Price: {{price}}
      <br>
      <ul>
        <li *ngFor="let feature of features">
          {{feature}}
        </li>
        <li *ngIf="packageId">
          <a (click)="selectPackage(packageId)">Select Package</a>
        </li>
      </ul>
    </div>
    <div *ngIf="!showCase">
      Standard Package
      <br>
      Data: {{dataPackage}}
      <br>
      Price: {{price}}
      <br>
      <ul>
        <li *ngFor="let feature of features">
          {{feature}}
        </li>
        <li *ngIf="packageId">
          <a (click)="selectPackage(packageId)">Select Package</a>
        </li>
      </ul>
    </div>
  `
})
export class SelectPackageComponent implements OnInit {

  @Input() packageId: string;
  @Input() showCase: boolean;
  @Input() dataPackage: string;
  @Input() price: string;
  @Input() features: string[];

  @Output() selectedPackage = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  selectPackage(packageId: string): void {
    this.selectedPackage.emit(packageId);
  }
}
