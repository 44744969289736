import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home.component';
import { CreateAccountComponent } from './pages/create-account.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions.component';
import { CookiePolicyComponent } from './pages/cookie-policy.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy.component';
import { AboutUsComponent } from './pages/about-us.component';
import { HelpCenterComponent } from './pages/help-center.component';
import { ContactUsComponent } from './pages/contact-us.component';
import { CreditBuilderPlusComponent } from './pages/credit-builder-plus.component';
import { BoshhhModule } from 'boshhh';
import { HttpClientModule } from '@angular/common/http';
import { CallRecordPolicyComponent } from './pages/call-record-policy.component';
import { CallsDataRoamingChargesComponent } from './pages/calls-data-roaming-charges.component';
import { CodeOfPracticeComponent } from './pages/code-of-practice.component';
import { HowToAndFaqsComponent } from './pages/how-to-and-faqs.component';
import { RefundPolicyComponent } from './pages/refund-policy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { environment } from '../environments/environment';
import { NgxStripeModule } from 'ngx-stripe';
import { MobilesComponent } from './pages/mobiles.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CreateAccountComponent,
    HeaderComponent,
    FooterComponent,
    TermsAndConditionsComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    HelpCenterComponent,
    ContactUsComponent,
    CreditBuilderPlusComponent,
    CallRecordPolicyComponent,
    CallsDataRoamingChargesComponent,
    CodeOfPracticeComponent,
    HowToAndFaqsComponent,
    RefundPolicyComponent,
    MobilesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BoshhhModule.forRoute(environment),
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey)
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [FooterComponent],
})
export class AppModule {}
