import { Component, Input } from "@angular/core";

@Component({
  selector: 'lib-plan-standard',
  template: `
    <div class="row pl-0 pt-0 ">
      <div class="col-12 text-center">
        <img alt="Credit builder" class="col-6 my-3" src="assets/images/{{creditBuilderImage}}" hidden />
        <div class="col-6">&nbsp;</div>
        <h2 class="mb-0 lh-auto" [innerHTML]="price"></h2>
      </div>
      <ul class="pl-0 mb-0 pt-2 list-unstyled text-dark-gray text-center" [innerHTML]="feature">

        </ul>

    </div>
  `,
  styles: [``],
})
export class PlanStandardComponent {
  @Input() title: string;
  @Input() price: string;
  @Input() feature: string;
  @Input() creditBuilderImage: string;

  constructor() {}
}
