import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calls-data-roaming-charges',
  template: `
    <content-calls-data-roaming-charges></content-calls-data-roaming-charges>
  `
})
export class CallsDataRoamingChargesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
