import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { CustomerDetailsComponent } from "./customer-details/customer-details.component";
import { AddressDetailsComponent } from "./address-details/address-details.component";
import { CardDetailsComponent } from "./card-details/card-details.component";
import { IOrderCompleteSummary } from "./order-complete-summary.model";
import { BoshhhStripeService } from "../../services/boshhh-stripe.service";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { MbillsService } from "../../services/mbills.service";
import { IRegisterModel } from "../../models/register.model";
import { PipedriveService } from "../../services/pipedrive.service";
import { IPipedriveCustomerDetailModel } from "../../models/pipedrive-customer-detail.model";
import { IPayment } from "../../models/payment.model";
import { PdfMonkeyService } from "../../services/pdf-monkey.service";
import { IPdfMonkeyUser } from "../../models/pdf-monkey-user.model";
import { IPdfMonkeyDocument } from "../../models/pdf-monkey-document.model";
import { TermsService } from "../../services/terms.service";

@Component({
  selector: 'lib-account-stepper',
  template: `
    <mat-stepper orientation="horizontal" linear="false" *ngIf="!formComplete" [formGroup]="masterFormGroup" #stepper>
      <ng-template matStepperIcon="edit">
        <mat-icon>done</mat-icon>
      </ng-template>

      <!-- 1.PERSONAL DETAILS -->
      <mat-step>
        <lib-customer-details formGroupName="customerDetailFormGroup"></lib-customer-details>
        <div class="text-end btn-next">
          <button mat-button (click)="doCustomerDetailsNext(stepper)">Next</button>
        </div>
      </mat-step>

      <!-- 2.SELECT PACKAGE -->
      <mat-step *ngIf="!preSelectedPackageId">
        <!-- DESKTOP VIEW -->
        <div class="d-none d-lg-block">
          <lib-sim-plans-selector
            [useEventEmitter]="true"
            (selectedPackage)="doSelectedPackage($event, stepper)"></lib-sim-plans-selector>
        </div>
        <!-- DESKTOP VIEW END -->
        <!-- MOBILE VIEW -->
        <div class="d-block d-lg-none">
          <lib-sim-plans-selector-mobile
            [useEventEmitter]="true"
            (selectedPackage)="doSelectedPackage($event, stepper)"></lib-sim-plans-selector-mobile>
        </div>
        <!--MOBILE VIEW END -->

        <div class="text-start btn-back">
          <button mat-button (click)="doStepperPrevious(stepper)">Back</button>
        </div>

      </mat-step>

      <!-- 3.ADDRESS DETAILS -->
      <mat-step>
        <lib-address-details
          formGroupName="billingAddressFormGroup"
          ></lib-address-details>
        <div class="row">
          <div class="col-6 text-start btn-back">
            <button mat-button (click)="doStepperPrevious(stepper)">Back</button>
          </div>
          <div class="col-6 text-end btn-next">
            <button mat-button (click)="doAddressDetailsNext(stepper)">Next</button>
          </div>
        </div>
      </mat-step>

      <!-- 5.CARD DETAILS -->
      <mat-step *ngIf="!useStripePayment">
        <lib-card-details  formGroupName="cardDetailsFormGroup"></lib-card-details>
        <div class="row">
          <div class="col-6 text-start btn-back">
            <button mat-button (click)="doStepperPrevious(stepper)">Back</button>
          </div>
          <div class="col-6 text-end">
            <!--<button mat-button (click)="doComplete()">Complete</button>-->
          </div>
        </div>

      </mat-step>
      <mat-step *ngIf="useStripePayment">
        <div class="row">
          <div class="col-6 text-start mb-3 btn-back">
            <button mat-button (click)="doStepperPrevious(stepper)">Back</button>
          </div>
        </div>
        <div *ngIf="selectedPackageId">
          <lib-selected-package [packageId]="selectedPackageId"></lib-selected-package>
        </div>

        <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">

          <div class="mt-3">
            <lib-stripe-payment-details
              [clientSecret]="clientSecret"
              [customerName]="customerName"
              [customerEmail]="customerEmail"
              [customerPhone]="customerPhone"
              [selectedPackageId]="selectedPackageId"
              (result)="stripeComplete($event)"></lib-stripe-payment-details>
          </div>

        </ng-container>
      </mat-step>
    </mat-stepper>

    <!-- 6.FORM COMPLETE -->
    <div *ngIf="formComplete">
      <lib-complete [orderCompleteSummary]="orderCompleteSummary"></lib-complete>
    </div>
    <style type="text/css">
      ::ng-deep .mat-horizontal-stepper-header {
        pointer-events: none !important;
      }
    </style>
  `,
  styles: [`
      .btn-next button{
        background: -webkit-linear-gradient(45deg,#009245,#003f37)!important;
        padding: 8px;
        width: 100%!important;
        color: #fff;
        font-size: 18px;
      }
      .btn-back button{
        border: #009245 solid 2px!important;
        background: none;
        color: #009245;
        padding: 8px;
        width: 100%!important;
        font-size: 18px;
      }
  `]
})
export class AccountStepperComponent implements OnInit {

  // acceptedTermsFormGroup: FormGroup;
  @Input() preSelectedPackageId: string;

  acceptedTerms: boolean = false;
  useStripePayment: boolean = true;
  masterFormGroup: FormGroup;
  customerDetailFormGroup: FormGroup;
  billingAddressFormGroup: FormGroup;
  cardDetailsFormGroup: FormGroup;
  packageDetailsFormGroup: FormGroup;
  stripeDetailsFormGroup: FormGroup;

  formComplete: boolean = false;
  selectedPackageId: string;
  orderCompleteSummary:IOrderCompleteSummary;
  stripCustomerId: string;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  pipedriveCustomerDetail: IPipedriveCustomerDetailModel;
  pipedriveTest: IPayment;
  stripeId: string;
  registerModel: IRegisterModel;
  pdfMonkeyUser: IPdfMonkeyUser;
  pdfMonkeyDocument: IPdfMonkeyDocument;
  documentId: string;

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  @ViewChild(CustomerDetailsComponent) customerDetailsComponent:CustomerDetailsComponent;
  @ViewChild(AddressDetailsComponent) addressDetailsComponent:AddressDetailsComponent;
  @ViewChild(CardDetailsComponent) cardDetailsComponent:CardDetailsComponent;

  constructor(
    private fb: FormBuilder,
    private boshhhStripeService: BoshhhStripeService,
    private mbillsService: MbillsService,
    private pipedriveService: PipedriveService,
    private pdfMonkeyService: PdfMonkeyService,
    private termsService: TermsService
  ) {}

  ngOnInit(): void {
    // this.acceptedTermsFormGroup = this.fb.group({});
    this.selectedPackageId = this.preSelectedPackageId;
    this.initializeMasterForm();
    this.initializeStripe();
  }

  doCustomerDetailsNext(stepper: MatStepper): void {
    if (this.customerDetailFormGroup.valid) {
      document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
      this.populatePipedriveCustomerDetails();
      stepper.next();
    } else {
      this.customerDetailsComponent.causeValidation();
    }
  }

  doSelectedPackage(selectedPackageId: string, stepper: MatStepper): void {
    this.selectedPackageId = selectedPackageId;
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
    stepper.next();
  }

  doAddressDetailsNext(stepper: MatStepper): void {
    if (this.billingAddressFormGroup.valid) {
      this.populateCustomerDetails();
      this.populatePipedriveCustomerDetails();
      this.loadStrip();
      document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
      this.sendContract();
      stepper.next();
    }
  }

  populateCustomerDetails(): void {
    this.customerName = this.customerDetailFormGroup.controls['firstName'].value + ' ' +
      this.customerDetailFormGroup.controls['lastName'].value;
    this.customerPhone = this.customerDetailFormGroup.controls['telephone'].value;
    this.customerEmail = this.customerDetailFormGroup.controls['emailAddress'].value;
  }

  initializeStripe(): void {
    this.boshhhStripeService.config()
      .subscribe(result => {
        console.log(result);
      });
  }

  loadStrip(): void {
    this.boshhhStripeService.createCustomer(
      this.customerEmail,
      this.customerPhone,
      this.customerName)
      .subscribe(result => {
      this.stripCustomerId = result.customer.id;
    }, error => {}, () => {
      this.createSubscription(this.stripCustomerId, this.selectedPackageId);
    });

  }

  createSubscription(customerId: string, priceId: string): void {
    this.boshhhStripeService.createSubscription(customerId, priceId)
      .subscribe(result => {
        this.elementsOptions.clientSecret = result.clientSecret;
        this.stripeId = result.id;
      });
  }

  stripeComplete(result: any): void {
    this.orderCompleteSummary = {
      name: this.customerDetailFormGroup.getRawValue().firstName + ' ' + this.customerDetailFormGroup.getRawValue().lastName,
      emailAddress: this.customerDetailFormGroup.getRawValue().emailAddress,
      deliveryAddress:
        this.billingAddressFormGroup.getRawValue().billingStreet1 + ', ' +
        this.billingAddressFormGroup.getRawValue().locality + ', ' +
        this.billingAddressFormGroup.getRawValue().billingCity + ', ' +
        this.billingAddressFormGroup.getRawValue().county + ', ' +
        this.billingAddressFormGroup.getRawValue().billingPostcode.toUpperCase() + '.'
    }
    this.formComplete = true;
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)

    this.registerModel = {
      customerDetailModel: {
        title: this.customerDetailFormGroup.getRawValue().title,
        firstName: this.customerDetailFormGroup.getRawValue().firstName,
        lastName: this.customerDetailFormGroup.getRawValue().lastName,
        emailAddress: this.customerDetailFormGroup.getRawValue().emailAddress,
        telephone: this.customerDetailFormGroup.getRawValue().telephone
      },
      addressDetailModel: {
        billingStreet1: this.billingAddressFormGroup.getRawValue().billingStreet1,
        billingLocality: this.billingAddressFormGroup.getRawValue().locality,
        billingCity: this.billingAddressFormGroup.getRawValue().billingCity,
        billingCounty: this.billingAddressFormGroup.getRawValue().county,
        billingPostcode: this.billingAddressFormGroup.getRawValue().billingPostcode.toUpperCase(),
        billingCountry: 'GB'
      },
      stripeDetailModel: {
        customerId: this.stripCustomerId,
        stripeId: result.paymentIntent.id
      },
      sentToMBills: false,
      packageId: this.selectedPackageId
    }

    this.mbillsService.register(this.registerModel)
      .subscribe(result => {
        console.log('mbills', result);
      });
  }

  sendContract(): void {
    const date = new Date();

    this.pdfMonkeyService.getPdfMonkey()
      .subscribe(result => {
        this.pdfMonkeyUser = result;
        this.pdfMonkeyDocument = {
          document_template_id: '51901C51-D726-46E3-9247-BAD347ED3EF4',
          payload: {
            name: this.customerDetailFormGroup.controls['title'].value + ' ' + this.customerDetailFormGroup.controls['firstName'].value + ' ' + this.customerDetailFormGroup.controls['lastName'].value,
            addressLine1: this.billingAddressFormGroup.getRawValue().billingStreet1 + ' ' + this.billingAddressFormGroup.getRawValue().locality,
            addressLine2: this.billingAddressFormGroup.getRawValue().billingCity + ' ' + this.billingAddressFormGroup.getRawValue().county,
            postcode: this.billingAddressFormGroup.getRawValue().billingPostcode.toUpperCase(),
            signed: this.customerDetailFormGroup.controls['firstName'].value + ' ' + this.customerDetailFormGroup.controls['lastName'].value,
            printedName: this.customerDetailFormGroup.controls['title'].value + ' ' + this.customerDetailFormGroup.controls['firstName'].value + ' ' + this.customerDetailFormGroup.controls['lastName'].value,
            date: date.toDateString()
          },
          meta: {
            _filename: 'boshhh-credit-account-application.pdf',
            client_id: result.id
          },
          status: 'pending'
        }
      }, error => {
      }, () => {
        this.pdfMonkeyService.postPdfMonkeyDocument(this.pdfMonkeyDocument)
          .subscribe(result2 => {
            this.documentId = result2.document.id;
          }, error2 => {
          }, () => {
            /*this.termsService.acceptedTerms(this.documentId, this.customerDetailFormGroup.controls['emailAddress'].value)
              .subscribe(result => {
              })*/
          })
      });
  }

  private initializeMasterForm(): void {
    this.packageDetailsFormGroup = this.fb.group({
      selectedPackageId: ['']
    });

    this.customerDetailFormGroup = this.fb.group({
      title: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      dateOfBirth: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^[^_]+$')]],
      telephone: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]]
    });

    this.billingAddressFormGroup = this.fb.group({
      billingPostcode: ['', Validators.required],
      billingStreet1: ['', Validators.required],
      locality: ['', Validators.required],
      billingCity: ['', Validators.required],
      county: ['', Validators.required]
    });

    this.cardDetailsFormGroup = this.fb.group({
      dateOfBirth: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^[^_]+$')]],
      cardNumber: ['', Validators.required],
      cardHolder: ['', Validators.required],
      expiryMonth: ['', Validators.required],
      expiryYear: ['', Validators.required],
      cvv: ['', [Validators.required, Validators.maxLength(4)]]
    });



    this.stripeDetailsFormGroup = this.fb.group({
      customerId: [''],
      stripeId: ['']
    });

    this.masterFormGroup = this.fb.group({
      customerDetailFormGroup: this.customerDetailFormGroup,
      billingAddressFormGroup: this.billingAddressFormGroup,
      stripeDetailsFormGroup: this.stripeDetailsFormGroup,
      // acceptedTermsFormGroup: this.acceptedTermsFormGroup
      // cardDetailsFormGroup: this.cardDetailsFormGroup,
      // packageDetailsFormGroup: this.packageDetailsFormGroup
    });
  }

  doStepperPrevious(stepper: MatStepper): void {
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0);
    stepper.previous();
  }

  private populatePipedriveCustomerDetails(): void {
    this.pipedriveCustomerDetail = {
      title: this.customerDetailFormGroup.getRawValue().title,
      firstName: this.customerDetailFormGroup.getRawValue().firstName,
      lastName: this.customerDetailFormGroup.getRawValue().lastName,
      dateOfBirth: this.customerDetailFormGroup.getRawValue().dateOfBirth,
      emailAddress: this.customerDetailFormGroup.getRawValue().emailAddress,
      telephone: this.customerDetailFormGroup.getRawValue().telephone,
      billingStreet1: this.billingAddressFormGroup.getRawValue().billingStreet1,
      billingLocality: this.billingAddressFormGroup.getRawValue().locality,
      billingCity: this.billingAddressFormGroup.getRawValue().billingCity,
      billingCounty: this.billingAddressFormGroup.getRawValue().county,
      billingPostcode: this.billingAddressFormGroup.getRawValue().billingPostcode.toUpperCase(),
      paymentDeclined: false,
      paymentMade: false,
      id: '',
      referenceId: ''
    }
    this.pipedriveService.customerDetails(this.pipedriveCustomerDetail)
      .subscribe(result => {
        console.log('pipedrive', result);
    });
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date: Date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-')
    );
  }

  /*formatDate(date: Date) {
    return (
      [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        this.padTo2Digits(date.getHours()),
        this.padTo2Digits(date.getMinutes()),
        this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }*/
}
