import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-and-conditions',
  template: `
    <div class="container">
      <content-terms-and-conditions></content-terms-and-conditions>
    </div>
  `
})
export class TermsAndConditionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
