import { Component, Input, OnInit } from "@angular/core";
import { IOrderCompleteSummary } from "../order-complete-summary.model";

@Component({
  selector: 'lib-complete',
  template: `
    <div class="p-3">
      <h2>Order Complete</h2>
      <p>
        {{orderCompleteSummary.name}}, Good news! Your order has been placed.
      </p>
      <p>
        We will now process the order internally and aim to get your new product
        to you within 3 working days. If we encounter any issues with your order
        we will contact you to discuss this further.
      </p>
      <p>
        <strong>Delivery: </strong>{{orderCompleteSummary.deliveryAddress}}
      </p>
      <p>
        <strong>Email: </strong>{{orderCompleteSummary.emailAddress}}
      </p>
    </div>
  `
})
export class CompleteComponent implements OnInit {

  @Input() orderCompleteSummary: IOrderCompleteSummary;

  constructor() {}

  ngOnInit(): void {

  }
}
