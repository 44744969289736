import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { StripePaymentElementComponent, StripeService } from "ngx-stripe";
import { MatStepper } from "@angular/material/stepper";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ISelectedPackage } from "./selected-package.model";
import { PipedriveService } from "../../../services/pipedrive.service";

@Component({
  selector: 'lib-stripe-payment-details',
  template: `
    <ng-container>
      <ngx-stripe-payment [clientSecret]="clientSecret"></ngx-stripe-payment>
      <form [formGroup]="acceptForm">
      <lib-signed-agreement (acceptedTerms)="doAcceptedTerms($event)"></lib-signed-agreement>
      </form>
      <button [disabled]="acceptedTerms" mat-button (click)="doConfirmPayment()" class="confirm-button">CONFIRM</button>
    </ng-container>
  `,
  styles: [`
    .confirm-button {
      margin-top: 10px;
      display: block;
      background-color: green;
      width: 100%!important;
      min-width: unset!important;
      color: #fff;
    }

    mat-button {
      width: 100%!important;
    }
  `]
})
export class StripePaymentDetailsComponent implements OnInit {
  // acceptedTermsFormGroup: FormGroup;
  acceptForm: FormGroup;
  acceptedTerms: boolean = false;
  @Input() clientSecret: string;
  @Input() customerName: string;
  @Input() customerEmail: string;
  @Input() customerAddress: string;
  @Input() customerPhone: string;
  @Input() selectedPackageId: string;

  @Output() result = new EventEmitter<any>();

  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;

  isPaymentInProgress: boolean = false;
  selectedPackage: ISelectedPackage;

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private pipedriveService: PipedriveService
  ) { }

  ngOnInit(): void {
    /*this.acceptedTermsFormGroup = this.fb.group({
    acceptedTerms: ['', Validators.required]
    });*/
    this.acceptForm = this.fb.group({});
    }

  doAcceptedTerms(acceptedTerms: boolean) {
    this.acceptedTerms = !acceptedTerms;
  }



  /*doSignedAgreement(stepper: MatStepper): void {
    if (this.acceptedTermsFormGroup.valid) {
      stepper.next();
    } else {
      console.log('Invalid');
    }
  }*/

  doConfirmPayment(): void {
    // prevent processing payments more than once.
    if (!this.isPaymentInProgress) {
      this.isPaymentInProgress = true;
      this.stripeService.confirmPayment({
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.customerName,
              email: this.customerEmail,
              phone: this.customerPhone,
              // todo: Pass in billing address object
              // address: this.clientAddress
            }
          }
        },
        redirect: 'if_required'
      }).subscribe(result => {
        if (result.error) {
          // todo: return user with a payment error.
          this.pipedriveService.paymentDeclined(this.customerEmail)
            .subscribe(result => {
              console.log('pipedrive', result);
            });
          console.log('error: ', result.error.message);
        } else {
          if (result.paymentIntent?.status === 'succeeded') {
            this.pipedriveService.paymentMade(this.customerEmail)
              .subscribe(result => {
                console.log('pipedrive', result);
              });
            console.log('success');
            console.log(result);
            this.result.emit(result);
          }
        }
      });
    }
  }

  setSelectedPackageDetails(): void {
    this.selectedPackage = {
      name: 'name test',
      description: 'description test',
      pricePerMonth: '�22.99 p/m',
      setupFee: '�7.99'
    }
  }
}
