import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  template: `
    <div class="container">
      <content-privacy-policy></content-privacy-policy>
    </div>
  `
})
export class PrivacyPolicyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
