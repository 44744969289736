import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { IPdfMonkeyUser } from "../models/pdf-monkey-user.model";
import { IPdfMonkeyDocument, IPdfMonkeyDocumentResponse } from "../models/pdf-monkey-document.model";

@Injectable({
  providedIn: 'root'
})
export class PdfMonkeyService {

  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }

  public getPdfMonkey(): Observable<IPdfMonkeyUser> {
    return this
        .http
        .get<any>('https://api.pdfmonkey.io/api/v1/current_user',
          this.httpOptions
        );
  }

  public postPdfMonkeyDocument(pdfMonkeyDocument: IPdfMonkeyDocument): Observable<IPdfMonkeyDocumentResponse> {
    return this
      .http
      .post<IPdfMonkeyDocumentResponse>('https://api.pdfmonkey.io/api/v1/documents',
        pdfMonkeyDocument,
        this.httpOptions
        );
  }

  public getPdfMonkeyDocument(downloadUrl: string): Observable<any> {
    return this
      .http
      .get(`https://api.pdfmonkey.io/api/v1/documents/${downloadUrl}`,
        this.httpOptions
      );
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      'Authorization': `${this.env.pdfMonkeyToken}`
    })
  };
}
