import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { IRegisterModel } from "../models/register.model";

@Injectable({
  providedIn: 'root'
})
export class MbillsService {

  constructor(
    private http: HttpClient,
    // @ts-ignore
    @Inject('env') private env
  ) { }

  public register(body: IRegisterModel): Observable<any> {
    return this
      .http
      .post<IRegisterModel>(`${this.env.mbillsApi}/api/register`,
        body,
        this.httpOptions
      );
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'
    })
  };
}
