import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'lib-footer-hello-temp',
  template: `
    <div class="container pl-0 pr-0">
      <hr class="hr-brand">
      <div class="pl-4 pr-4 text-center">
        <img alt="Logo" src="/assets/images/logo-inverted.png" width="150px" class="mb-4" />
        <mat-form-field appearance="outline" >
          <mat-label class="text-gray-dark">Search help center</mat-label>
          <input matInput placeholder="Placeholder">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <ul class="list-unstyled mt-4">
          <li class="mb-4">
            <a class="text-decoration-none font-size-lg text-green" href="https://boshhh.mbills.co.uk/Activate">Activate SIM</a>
          </li>
          <li class="mb-4">
            <a class="text-decoration-none font-size-lg text-gray-dark" href="https://boshhh.mbills.co.uk/Login">Login</a>
          </li>
          <li class="mb-4">
            <a class="text-decoration-none font-size-lg text-cbp-blue-dark" role="button"
               (click)="navigate('/credit-builder-plus')">Credit Builder <b class="text-cbp-blue-light">+</b></a>
          </li>
        </ul>
        <p class="stay-connected">
          Stay connected...
        </p>
        <a href="https://www.instagram.com/boshhhuk/">
          <img alt="Instagram" src="assets/images/social-instagram.png" width="50px" class="mr-3 mb-3" />
        </a>
        <a href="https://www.facebook.com/people/Boshhh/100087822891371/">
          <img alt="Facebook" src="assets/images/social-facebook.png" width="50px" class="mb-3" />
        </a>
        <a href="https://www.youtube.com/channel/UC5pybccpBc08dsejUZriaWA">
          <img alt="YouTube" src="assets/images/social-youtube.png" width="50px" class="ml-3 mb-3" />
        </a>
        <a href="https://twitter.com/boshhhuk">
          <img alt="Twitter" src="assets/images/social-twitter.png" width="50px" class="ml-3 mb-3" />
        </a>
        <a href="https://www.tiktok.com/@boshhhuk">
          <img alt="TikTok" src="assets/images/social-tiktok.png" width="50px" class="ml-3 mb-3" />
        </a>

        <p>
          <small>
            &copy; 2022 Boshhh Ltd. All rights reserved.
            <br>
            Registered in England and Wales. No. 13188665.
            <br>
            140 Lee Lane, Horwich, Bolton, England, BL6 7AF.
            <br>
            <a href="mailto:hello@boshhh.com">hello@boshhh.com</a>
          </small>
        </p>
      </div>
    </div>
  `,
  styles: [`
    a:hover{
      cursor: pointer;
    }
  `],
})
export class FooterHelloTempComponent implements OnInit {
  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {}

  public navigate (route: string): void {
    console.log('using custom route');
    this.router.navigateByUrl(route)
    document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0)
  }
}
