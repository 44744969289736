<div class="content-page-container">
  <h2>Code of Practice</h2>

  <p>
    The telecoms industry is strictly regulated and we have a detailed Code of
    Practice licensed by OFCOM.
  </p>

  <h3>Our primary commitments are:</h3>

  <ul>
    <li>
      To use plain English - we will ensure that all information is simple, easy
      to understand, prevent mis-selling through technical information and
      misunderstanding.
    </li>
    <li>
      Best practice - our team is recruited and trained based on delivering
      market leading customer value, respect and fair treatment.
    </li>
    <li>
      Fault reporting calls may be recorded for full evidence to ensure we
      protect our clients, should there be any need to escalate an issue to the
      appropriate head of department.
    </li>
    <li>
      Sales and Marketing - we approach customers via a wide range of methods,
      however, regardless of the way in which our sales and marketing activities
      are conducted, we act responsibly and comply with ‘our code’ at all times
      with the Advertising Standards Agency.
    </li>
    <li>
      Customer contact – we will respect privacy and act in a non-intrusive
      manner.
    </li>
    <li>
      Our administration will be clear so that customers have a full
      understanding of their undertaking.
    </li>
    <li>
      Cooling off and cancellation terms will be highlighted and fully explained
      at the outset.
    </li>
    <li>
      Customer complaint handling will be rigorous and handled in accordance
      with the best practice.
    </li>
    <li>
      Service reliability will be optimised at all times and we will monitor our
      service provision constantly.
    </li>
    <li>
      Customers with disabilities – in consultation with the Consumer Panel, we
      will ensure that the requirements and interests of disabled customers are
      fully taken into account in the development and provision of services.
    </li>
    <li>
      Call metering and charges - we will ensure that billing reflects
      accurately to the use of services.
    </li>
  </ul>
  <p>
    For full details, please contact us at
    <a href="mailto:codeofpractice@boshhh.com">codeofpractice@boshhh.com</a> to
    request a full copy of our terms & conditions.
  </p>
</div>
