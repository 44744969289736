import { Component, Inject } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  template: `
    <lib-jumbotron></lib-jumbotron>

    <div class="text-center container mt-5" style="padding: 1rem 1rem">
      <h1 class="mb-4 desktop-heading">
        Credit Builder + improves your credit score with the UK's main Credit
        Reference Agencies
      </h1>
      <p>
        Boshhh is a SIM only mobile network provider in partnership with the U.K’s
        Credit agencies – we promote a positive influence on your credit file and
        get you back on track, the credit Builder + SIM is the first step in
        transforming your credit score.
      </p>
    </div>
    <hr class="hr-brand mt-5 mb-1" />
    <div *ngIf="!production" class="sticket-section py-5">
      <div class="container">
        <div class="row">
          <div class="col-12 d-flex flex-wrap justify-content-start align-items-center sticker-block">
            <div class="img pe-2">
              <img src="../../assets/images/bish.svg" >
            </div>
              <div class="ms-3">
                 <p>Apply for your</p>
                  <p>boshhh guaranted</p>
                  <p>SIM contact</p>
              </div>
          </div>
          <div class="col-12 d-flex flex-wrap justify-content-start flex-row-reverse align-items-center sticker-block">
            <div class="img pe-2">
              <img src="../../assets/images/bash.svg" >
            </div>
              <div class="me-3 bash-section">
                 <p>Make regular payment on time</p>
                 <p>whilist enjoying up-to unlimited </p>
                 <p>data, calls and texts</p>
              </div>
          </div>
          <div class="col-12 d-flex flex-wrap justify-content-start align-items-center sticker-block">
            <div class="img pe-2">
              <img src="../../assets/images/boshhh.png" >
            </div>
              <div class="ms-3">
                 <p>What your </p>
                 <p>credit score</p>
                 <p>grow</p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jumbotron-second text-center pt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-6 d-flex align-items-center text-left">
            <h1 class="desktop-heading mb-5">
              We will notify credit agencies of your successful Payments made to Boshhh.
            </h1>
          </div>
          <div class="col-md-6 col-lg-6">
            <img
              alt="Credit score on phone"
              src="assets/images/home-phone-with-credit-score-2.png"
              class="col-6 pl-4 mt-3 mb-5"
              style="max-width: 400px"/>
          </div>
        </div>
      </div>


    </div>
    <hr class="hr-brand mt-1 mb-5" />

    <!-- START Plan Selector Component -->
    <div class="text-center">
      <h1 class="mb-4 mt-5">SIM Plans</h1>
    </div>
    <div class="container">
      <div class="row">
        <a href="/create-account">
          <img
            src="/assets/images/home-unlimited-package.png"
            class="col-12 pl-4 pr-4 mb-5"
            style="max-width: 400px"
          />
        </a>
      </div>
    </div>
    <!-- END Plan Selector Component -->
    <div class="pl-3 pr-3 mt-5">
      <h1 class="desktop-heading text-center">
        The first and only network focused on increasing your credit score.
      </h1>
      <p></p>
    </div>
    <div *ngIf="!production" class="text-center mt-5 pt-5">
      <p>
        YouTube PlaceHolder
      </p>
    </div>
    <lib-how-it-works></lib-how-it-works>
    <lib-frequently-asked-questions></lib-frequently-asked-questions>
  `,
})
export class HomeComponent {

  production: boolean = false;

  constructor(
    private router: Router,
    // @ts-ignore
    @Inject('env') private env
    ) {
    this.production = this.env.production;
  }

  doSelectPackage(selectedPackageId: string): void {
    this.router.navigate(['/create-account/', { id : selectedPackageId }]).then();
  }
}
