import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: 'lib-package-details',
  template: `
    <lib-select-package
      [packageId]="'1'"
      [showCase]="true"
      [dataPackage]="'10GB'"
      [price]="'£22.99'"
      [features]="[
        'Unlimited Minutes',
        'Unlimited Texts',
        '10GB Data',
        'Credit Builder Plus'
      ]"
      (selectedPackage)="doSelectPackage($event)"
    ></lib-select-package>
    <lib-select-package
      [packageId]="'2'"
      [dataPackage]="'30GB DATA'"
      [price]="'£29.99'"
      [features]="['Credit Builder Plus']"
      (selectedPackage)="doSelectPackage($event)"
    ></lib-select-package>
    <lib-select-package
      [packageId]="'3'"
      [dataPackage]="'3GB DATA'"
      [price]="'£15.99'"
      [features]="['Credit Build']"
      (selectedPackage)="doSelectPackage($event)"
    ></lib-select-package>
  `
})
export class PackageDetailsComponent implements OnInit {

  @Output() selectedPackage = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  doSelectPackage(selectedPackageId: string): void {
    this.selectedPackage.emit(selectedPackageId);
  }
}
