import { Component, Input } from "@angular/core";

@Component({
  selector: 'lib-plan-featured',
  template: `
    <div class="row pl-0 pt-0 ">
      <div class="col-12 text-center">
        <h2 class="mb-3 text-secondary fs-2  fw-bold lh-2" [innerHTML]="title"></h2>
        <h2 class="mb-0 lh-auto" [innerHTML]="price"></h2>
      </div>
      <ul class="pl-0 mb-0 pt-2 list-unstyled text-dark-gray text-center" [innerHTML]="feature">

        </ul>
    </div>

  `,
  styles: [``],
})
export class PlanFeaturedComponent {
  @Input() title: string;
  @Input() price: string;
  @Input() feature: string;

  constructor() {}
}
