import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";

@Component({
  selector: 'lib-signed-agreement',
  template: `
    <form>
      <div class="p-3">
        <div class="row">
          <div class="col-1">
            <mat-checkbox class="example-margin" (change)="doChanged(toggle)"></mat-checkbox>
          </div>
          <div class="col-11">
            <p>
              I hereby authorise Boshhh Ltd to obtain references, including submitting information to
              credit reference agencies as and when appropriate. I agree to abide by the
              <a target="_blank" href="https://boshhh.com/terms-and-conditions">Terms and Conditions</a> as set out by
              Boshhh LTD.
            </p>
          </div>
        </div>
      </div>
    </form>
  `,
  styles: [],
})
export class SignedAgreementComponent implements OnInit {

  toggle: boolean = false;
  // @Input() formGroupName: string;
  @Output() acceptedTerms = new EventEmitter<boolean>();

  signedAgreementFormGroup: FormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective
  ) {}

  doChanged(acceptedTerms: boolean): void {
    acceptedTerms = !acceptedTerms;
    this.acceptedTerms.emit(acceptedTerms);
  }

  ngOnInit(): void {
    /*this.signedAgreementFormGroup =
      this.rootFormGroup.control.get(this.formGroupName) as FormGroup;*/
  }
}
