import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "./pages/home.component";
import { CreateAccountComponent } from "./pages/create-account.component";
import { TermsAndConditionsComponent } from "./pages/terms-and-conditions.component";
import { CookiePolicyComponent } from "./pages/cookie-policy.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy.component";
import { AboutUsComponent } from "./pages/about-us.component";
import { HelpCenterComponent } from "./pages/help-center.component";
import { ContactUsComponent } from "./pages/contact-us.component";
import { CreditBuilderPlusComponent } from "./pages/credit-builder-plus.component";
import { CallRecordPolicyComponent } from "./pages/call-record-policy.component";
import {
  CallsDataRoamingChargesComponent
} from "./pages/calls-data-roaming-charges.component";
import { CodeOfPracticeComponent } from "./pages/code-of-practice.component";
import { HowToAndFaqsComponent } from "./pages/how-to-and-faqs.component";
import { RefundPolicyComponent } from "./pages/refund-policy.component";
import { MobilesComponent } from "./pages/mobiles.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'create-account',
    component: CreateAccountComponent
  },
  {
    path: 'mobiles',
    component: MobilesComponent
  },
  {
    path: 'create-account/:id',
    component: CreateAccountComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'help-center',
    component: HelpCenterComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'call-record-policy',
    component: CallRecordPolicyComponent
  },
  {
    path: 'calls-data-roaming-charges',
    component: CallsDataRoamingChargesComponent
  },
  {
    path: 'code-of-practice',
    component: CodeOfPracticeComponent
  },
  {
    path: 'how-to-and-faqs',
    component: HowToAndFaqsComponent
  },
  {
    path: 'refund-policy',
    component: RefundPolicyComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
