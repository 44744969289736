import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-builder-plus',
  template: `
    <div class="container">
      <content-credit-builder-plus [showButton]="true"></content-credit-builder-plus>
    </div>
  `
})
export class CreditBuilderPlusComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
