<div class="content-page-container">
  <h2>Privacy Policy</h2>
  <p>
    Boshhh Ltd respects your privacy and is committed to protecting your
    personal data.
  </p>
  <p>
    This Privacy Policy describes how your personal information is collected,
    used, and shared when you visit or make a purchase from Boshhh Ltd,
    boshhh.com (the “Site”).
  </p>
  <p>
    The Site is not intended for children and we do not knowingly collect data
    relating to children.
  </p>

  <p>
    It is important that you read this Privacy Policy together with any other
    privacy policy or fair processing policy we may provide on specific
    occasions when we are collecting or processing personal data about you so
    that you are fully aware of how and why we are using your data.
  </p>

  <p>
    Boshhh Ltd is the controller and responsible for your personal data
    (collectively referred to as “Boshhh Ltd”, “we”, “us” or “our” in this
    Privacy Policy).
  </p>

  <h3>Personal information we collect</h3>

  <p>
    When you visit the Site, we automatically collect certain information about
    your device, including information about your web browser, IP address, time
    zone, and some of the cookies that are installed on your device.
    Additionally, as you browse the Site, we collect information about the
    individual web pages or products that you view, what websites or search
    terms referred you to the Site, and information about how you interact with
    the Site. We refer to this automatically-collected information as “Device
    Information”.
  </p>

  <p>We collect Device Information using the following technologies:</p>

  <ul>
    <li>
      “Cookies” are data files that are placed on your device or computer and
      often include an anonymous unique identifier. For more information about
      cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
    </li>
    <li>
      “Log files” track actions occurring on the Site, and collect data
      including your IP address, browser type, Internet service provider,
      referring/exit pages, and date/time stamps.
    </li>
    <li>
      “Web beacons”, “tags”, and “pixels” are electronic files used to record
      information about how you browse the Site.
    </li>
  </ul>

  <p>
    Additionally when you make a purchase or attempt to make a purchase through
    the Site, we collect certain information from you, including your name,
    billing address, shipping address, payment information (including credit
    card, email address, and phone number). We refer to this information as
    “Order Information”.
  </p>

  <p>
    When we talk about “Personal Information” in this Privacy Policy, we are
    talking both about Device Information and Order Information.
  </p>

  <h3>How do we use your personal information?</h3>

  <p>
    We use the Order Information that we collect generally to fulfil any orders
    placed through the Site (including processing your payment information,
    arranging for shipping, and providing you with invoices and/or order
    confirmations). Additionally, we use this Order Information to:
  </p>

  <h3>to communicate with you;</h3>
  <ul>
    <li>to screen our orders for potential risk or fraud; and</li>
    <li>
      when in line with the preferences you have shared with us, provide you
      with information or advertising relating to our products or services.
    </li>
    <li>
      when in line with the preferences you have shared with us, to provide you
      with information or advertising relating to our products or services; and
    </li>
    <li>
      in accordance with clauses 4.5 to 4.7 of our Terms & Conditions of Prepay
      Mobile Services
      <a (click)="navigate('/terms')">https://boshhh.com/terms.</a>
    </li>
  </ul>

  <p>
    We use the Device Information that we collect to help us screen for
    potential risk and fraud (in particular, your IP address), and more
    generally to improve and optimise our Site (for example, by generating
    analytics about how our customers browse and interact with the Site, and to
    assess the success of our marketing and advertising campaigns).
  </p>

  <h3>Sharing your Personal Information</h3>

  <p>
    We share your Personal Information with third parties to help us use your
    Personal Information, as described above. We also use Google Analytics to
    help us understand how our customers use the Site -- you can read more about
    how Google uses your Personal Information here:
    https://www.google.com/intl/en/policies/privacy/. You can also opt-out of
    Google Analytics here: https://tools.google.com/dlpage/gaoptout.
  </p>

  <p>
    Finally, we may also share your Personal Information to comply with
    applicable laws and regulations, to respond to other lawful requests for
    information we receive, or to otherwise protect our rights.
  </p>

  <h3>Behavioural advertising</h3>

  <p>
    As described above, we use your Personal Information to provide you with
    targeted advertisements or marketing communications we believe may be of
    interest to you. For more information about how targeted advertising works,
    you can visit the Network Advertising Initiative’s (“NAI”) educational page
    at
    http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
  </p>

  <p>You can opt out of targeted advertising by using the links below:</p>

  <ul>
    <li>Facebook: https://www.facebook.com/settings/?tab=ads</li>
    <li>Google: https://www.google.com/settings/ads/anonymous</li>
    <li>
      Bing:
      https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
    </li>
  </ul>
  <p>
    Additionally, you can opt out of some of these services by visiting the
    Digital Advertising Alliance’s opt-out portal at:
    http://optout.aboutads.info/.
  </p>

  <h3>Do not track</h3>

  <p>
    Please note that we do not alter our Site’s data collection and use
    practices when we see a Do Not Track signal from your browser.
  </p>

  <h3>Data security</h3>

  <p>
    We have put in place appropriate security measures to prevent your personal
    data from being accidentally lost, used or accessed in an unauthorised way,
    altered or disclosed. In addition, we limit access to your personal data to
    those employees, agents, contractors and other third parties who have a
    business need to know. They will only process your personal data on our
    instructions and they are subject to a duty of confidentiality.
  </p>

  <p>
    We have put in place procedures to deal with any suspected personal data
    breach and will notify you and any applicable regulator of a breach where we
    are legally required to do so.
  </p>

  <h3>International transfers</h3>

  <p>We do not transfer your personal data outside the UK.</p>

  <h3>Cookies</h3>

  <p>
    You can set your browser to refuse all or some browser cookies, or to alert
    you when websites set or access cookies. If you disable or refuse cookies,
    please note that some parts of this Site may become inaccessible or not
    function properly.
  </p>

  <p>
    For more information about the cookies we use, please see
    <a (click)="navigate('/cookie-policy')"
      >https://boshhh.com/cookie-policy</a
    >.
  </p>

  <h3>Your legal rights</h3>

  <p>You have the right to:</p>

  <p>
    Request access to your personal data (commonly known as a "data subject
    access request"). This enables you to receive a copy of the personal data we
    hold about you and to check that we are lawfully processing it.
  </p>

  <p>
    You will not usually have to pay a fee to access your personal data (or to
    exercise any of the other rights). However, we may charge a reasonable fee
    if your request is clearly unfounded, repetitive or excessive.
    Alternatively, we could refuse to comply with your request in the
    circumstances described.
  </p>

  <p>
    We may need to request specific information from you to help us confirm your
    identity and ensure your right to access your personal data (or to exercise
    any of your other rights). This is a security measure to ensure that
    personal data is not disclosed to any person who has no right to receive it.
    We may also contact you to ask you for further information in relation to
    your request to speed up our response.
  </p>

  <p>
    We try to respond to all legitimate requests within one month. Occasionally
    it could take us longer than a month if your request is particularly complex
    or you have made a number of requests. In this case, we will notify you and
    keep you updated.
  </p>

  <p>
    Request correction of the personal data that we hold about you. This enables
    you to have any incomplete or inaccurate data we hold about you corrected,
    though we may need to verify the accuracy of the new data you provide to us.
  </p>

  <p>
    Request erasure of your personal data. This enables you to ask us to delete
    or remove personal data where there is no good reason for us continuing to
    process it. You also have the right to ask us to delete or remove your
    personal data where you have successfully exercised your right to object to
    processing (see below), where we may have processed your information
    unlawfully or where we are required to erase your personal data to comply
    with local law. Note, however, that we may not always be able to comply with
    your request of erasure for specific legal reasons which will be notified to
    you, if applicable, at the time of your request.
  </p>

  <p>
    Object to processing of your personal data where we are relying on a
    legitimate interest (or those of a third party) and there is something about
    your particular situation which makes you want to object to processing on
    this ground as you feel it impacts on your fundamental rights and freedoms.
    You also have the right to object where we are processing your personal data
    for direct marketing purposes. In some cases, we may demonstrate that we
    have compelling legitimate grounds to process your information which
    override your rights and freedoms.
  </p>

  <p>
    Request restriction of processing of your personal data. This enables you to
    ask us to suspend the processing of your personal data in the following
    scenarios:
  </p>

  <ul>
    <li>If you want us to establish the data's accuracy.</li>
    <li>
      Where our use of the data is unlawful but you do not want us to erase it.
    </li>
    <li>
      Where you need us to hold the data even if we no longer require it as you
      need it to establish, exercise or defend legal claims.
    </li>
    <li>
      You have objected to our use of your data but we need to verify whether we
      have overriding legitimate grounds to use it.
    </li>
  </ul>

  <p>
    Request the transfer of your personal data to you or to a third party. We
    will provide to you, or a third party you have chosen, your personal data in
    a structured, commonly used, machine-readable format. Note that this right
    only applies to automated information which you initially provided consent
    for us to use or where we used the information to perform a contract with
    you.
  </p>

  <p>
    Withdraw consent at any time where we are relying on consent to process your
    personal data. However, this will not affect the lawfulness of any
    processing carried out before you withdraw your consent. If you withdraw
    your consent, we may not be able to provide certain products or services to
    you. We will advise you if this is the case at the time you withdraw your
    consent.
  </p>

  <h3>Data retention</h3>

  <p>
    We will only retain your personal data for as long as reasonably necessary
    to fulfil the purposes we collected it for, including for the purposes of
    satisfying any legal, regulatory, tax, accounting or reporting requirements.
    We may retain your personal data for a longer period in the event of a
    complaint or if we reasonably believe there is a prospect of litigation in
    respect to our relationship with you.
  </p>

  <p>
    To determine the appropriate retention period for personal data, we consider
    the amount, nature and sensitivity of the personal data, the potential risk
    of harm from unauthorised use or disclosure of your personal data, the
    purposes for which we process your personal data and whether we can achieve
    those purposes through other means, and the applicable legal, regulatory,
    tax, accounting or other requirements.
  </p>

  <h3>Changes</h3>

  <p>
    We may update this Privacy Policy from time to time in order to reflect, for
    example, changes to our practices or for other operational, legal or
    regulatory reasons. It is important that the personal data we hold about you
    is accurate and current. Please keep us informed if your personal data
    changes during your relationship with us.
  </p>

  <h3>Third-party links</h3>

  <p>
    The Site may include links to third-party websites, plug-ins and
    applications. Clicking on those links or enabling those connections may
    allow third parties to collect or share data about you. We do not control
    these third-party websites and are not responsible for their privacy
    statements. When you leave our website, we encourage you to read the privacy
    policy of every website you visit.
  </p>

  <h3>Contact us</h3>

  <p>
    For more information about our privacy practices, if you have questions, or
    if you would like to make a complaint, please contact us by e-mail at
    privacy@boshhh.com or by using the details provided below:
  </p>

  <p>
    140 Lee Lane,
    <br />
    Horwich,
    <br />
    Bolton,
    <br />
    England,
    <br />
    BL6 7AF.
  </p>

  <p>Tel: 03333 4444 15</p>

  <p>
    You have the right to make a complaint at any time to the Information
    Commissioner's Office (“ICO”), the UK regulator for data protection issues
    (www.ico.org.uk). We would, however, appreciate the chance to deal with your
    concerns before you approach the ICO so please contact us in the first
    instance using one of the methods described above.
  </p>

  <h3>Boshhh Ltd</h3>
</div>
