<div class="text-center">
    <h1 class="mb-4 section-heading">
      SIM Plans
    </h1>
  </div>
  <style>
        .plan-badge{
          background: linear-gradient(153deg, rgb(51, 22, 165) 16%,  rgb(166, 81, 158) 69%, rgb(227, 57, 80) 105%) !important;
          position: absolute;
          right: 0;
          top: 0;
          color: #fff;
          font-weight: 600;
          padding: 15px 30px;
          border-radius: 0 30px;
        }
        .btn {
          background: -webkit-linear-gradient(45deg, #009245, #003f37);
          width: 100%;
        }
        
        
    </style>
  
  <div class="container p-2">
    <div class="row align-items-center" >
      
      <div class="col-sm-6 col-md-4 col-lg-4 block p-2">
        <div  class="plan mb-3 shadow-lg bg-body text-dark">
          <!-- START PACKAGE 3 -->
          <div *ngIf="!useEventEmitter">
            <a href="/create-account/{{priceIdThree}}" class="text-decoration-none text-black p-2 feature_plan">
              <h2 class="fw-bolder heading-color text-center">3GB Data</h2>
              <div class="shadow-line mx-auto my-2"></div>
              <lib-plan-standard
                creditBuilderImage="credit-builder-logo-3.png"
                price="<span class='fw-bold text-green'>£15.</span><small class='mr-3 text-secondary '><span class='text-green package-price'>99</span> GBP Monthly</small>"
                feature="<li>Unlimited Minutes</li>
                <li>Unlimited Texts</li>
                <li>3GB Data</li>
                <li>Credit Builder</li>
                <li class='text-blue'>5G Ready at no extra cost</li>
                <li class='text-blue'>Personal Hotspot</li>
                <li class='text-blue'>Go Roam Around the World</li>">
              </lib-plan-standard>
              <div class=" px-5">
                <button class="btn shadow-sm text-white" type="button">Buy Plan</button>
              </div>
            </a>
          </div>
          <div *ngIf="useEventEmitter">
            <a (click)="selectPackage(priceIdThree)" class="text-decoration-none text-black p-2">
              <h2 class="fw-bolder heading-color text-center">3GB Data</h2>
              <div class="shadow-line mx-auto my-2"></div>
              <lib-plan-standard
                creditBuilderImage="credit-builder-logo-3.png"
                price="<span class='fw-bold text-green'>£15.</span><small class='mr-3 text-secondary '><span class='text-green package-price'>99</span> GBP Monthly</small>"
                feature="<li>Unlimited Minutes</li>
                <li>Unlimited Texts</li>
                <li>3GB Data</li>
                <li>Credit Builder</li>
                <li class='text-blue'>5G Ready at no extra cost</li>
                <li class='text-blue'>Personal Hotspot</li>
                <li class='text-blue'>Go Roam Around the World</li>">
              </lib-plan-standard>
              <div class=" px-5">
                <button class="btn shadow-sm text-white" type="button">Buy Plan</button>
              </div>
            </a>
          </div>
          <!-- END PACKAGE 3 -->
  
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4 block">
        <div  class="plan mb-3 shadow-lg p-3 mb-5 bg-body text-dark position-relative">
          <!-- START PACKAGE 1 -->
          <div *ngIf="!useEventEmitter">
            <a href="/create-account/{{priceIdOne}}" class="text-decoration-none text-center text-dark p-2">
            <div class="plan-badge">Most Popular</div>
              <h2 class="fw-bolder heading-color ">10GB Data</h2>
              <div class="shadow-line mx-auto my-3"></div>
              <lib-plan-standard
                creditBuilderImage="credit-builder-plus-logo-1.png"
                price="<span class='fw-bold text-green'>£22.</span><small class='mr-3 text-secondary '><span class='text-green package-price'>99</span> GBP Monthly</small>"
                feature="
                <li>Unlimited Minutes</li>
                <li>Unlimited Texts</li>
                <li>10GB Data</li>
                <li>Credit Builder Plus</li>
                <li class='text-blue'>5G Ready at no extra cost</li>
                <li class='text-blue'>Personal Hotspot</li>
                <li class='text-blue'>Go Roam Around the World</li>"></lib-plan-standard>
                <button class="btn shadow-sm my-2 text-white" type="button">Buy Plan</button>
            </a>
          </div>
          <div *ngIf="useEventEmitter">
            <a (click)="selectPackage(priceIdOne)" class="text-decoration-none text-center text-dark p-2">
              <h2 class="fw-bolder heading-color ">10GB Data</h2>
              <div class="shadow-line mx-auto my-3"></div>
              <lib-plan-standard
                creditBuilderImage="credit-builder-plus-logo-1.png"
                price="<span class='fw-bold text-green'>£22.</span><small class='mr-3 text-secondary '><span class='text-green package-price'>99</span> GBP Monthly</small>"
                feature="
                <li>Unlimited Minutes</li>
                <li>Unlimited Texts</li>
                <li>10GB Data</li>
                <li>Credit Builder Plus</li>
                <li class='text-blue'>5G Ready at no extra cost</li>
                <li class='text-blue'>Personal Hotspot</li>
                <li class='text-blue'>Go Roam Around the World</li>"></lib-plan-standard>
                <button class="btn shadow-sm my-2 text-white" type="button">Buy Plan</button>
            </a>
          </div>
          <!-- START PACKAGE 1 -->
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4 block p-2">
        <div  class="plan mb-3 shadow-lg bg-body text-dark position-relative feature_plan">
  
          <!-- START PACKAGE 2 -->
          <div *ngIf="!useEventEmitter">
            <a href="/create-account/{{priceIdTwo}}" class="text-decoration-none text-center text-dark p-2">
            
              <h2 class="fw-bolder heading-color ">30GB Data</h2>
              <div class="shadow-line mx-auto my-2"></div>
              <lib-plan-standard
                creditBuilderImage="credit-builder-plus-logo-1.png"
                price="<span class='fw-bold text-green'>£29.</span><small class='mr-3 text-secondary '><span class='text-green package-price'>99</span> GBP Monthly</small>"
                feature="
                      <li>Unlimited Minutes</li>
                      <li>Unlimited Texts</li>
                      <li>30GB Data</li>
                      <li>Credit Builder Plus</li>
                      <li class='text-blue'>5G Ready at no extra cost</li>
                      <li class='text-blue'>Personal Hotspot</li>
                      <li class='text-blue'>Go Roam Around the World</li>">
              </lib-plan-standard>
              <div class=" px-5">
                <button class="btn shadow-sm text-white" type="button">Buy Plan</button>
              </div>
              
  
            </a>
          </div>
          <div *ngIf="useEventEmitter">
            <a (click)="selectPackage(priceIdTwo)" class="text-decoration-none text-center text-dark p-2">
              <h2 class="fw-bolder heading-color ">30GB Data</h2>
              <div class="shadow-line mx-auto my-2"></div>
              <lib-plan-standard
                creditBuilderImage="credit-builder-plus-logo-1.png"
                price="<span class='fw-bold text-green'>£29.</span><small class='mr-3 text-secondary '><span class='text-green package-price'>99</span> GBP Monthly</small>"
                feature="<li>Unlimited Minutes</li>
                        <li>Unlimited Texts</li>
                        <li>30GB Data</li>
                        <li>Credit Builder Plus</li>
                        <li class='text-blue'>5G Ready at no extra cost</li>
                        <li class='text-blue'>Personal Hotspot</li>
                        <li class='text-blue'>Go Roam Around the World</li>">
              </lib-plan-standard>
              <div class=" px-5">
                <button class="btn shadow-sm text-white" type="button">Buy Plan</button>
              </div>
  
            </a>
          </div>
          <!-- END PACKAGE 2 -->
  
        </div>
      </div>
  
      
    </div>
  </div>
  
  
  
  