<div class="content-page-container">
  <h2>Terms &amp; Conditions</h2>

  <h3>1. YOUR AGREEMENT</h3>

  <p>
    1.1 These are the terms and conditions on which mobile services (the
    “Services”) are provided to you by Boshhh Ltd, referred to in these terms
    and conditions as the “SUPPLIER” or “we”, “us” or “our”. When we say “you”,
    “your” or “Customer” we mean you, our customer. Your Agreement with us is
    also made up of the charges and other tariff details available and
    periodically updated on our website (boshhh.com) (Calls, Data & Roaming
    Charges), our Privacy Policy and Cookie Policy and any other supplemental
    (including promotional) terms and conditions that may be relevant from
    time-to-time for the Services you are using and which are published on our
    website, such as any fair use policy. Further information on the Services
    can also be found in our Help sections on our website boshhh.com.
  </p>

  <p>
    1.2 Please read these terms carefully before you submit your order to us.
    These terms tell you who we are, how we will provide our Services to you,
    how you and we may change or end the Services, what to do if there is a
    problem and other important information. By creating an account, purchasing
    a bundle or inserting or using a SUPPLIER SIM card to access the services
    you will be deemed to have accepted the terms of the Agreement.
  </p>

  <p>
    1.3. We will connect you to the network as soon as possible so you can
    access the Services.
  </p>

  <h3>2. INFORMATION ABOUT US AND HOW TO CONTACT US</h3>

  <p>
    2.1 We are Boshhh Ltd and our company registration number is 13188665, our
    VAT number is 363 4457 87 and our registered office address is 140 Lee Lane,
    Horwich, Bolton, England, BL6 7AF.
  </p>

  <p>
    2.2 You can contact us by telephoning our customer service team on 500 from
    the SUPPLIER mobile network, or 03333 4444 15 from a landline or
    non-SUPPLIER mobile network. You can also contact us in the ways set out in
    our Help pages on our website.
  </p>

  <p>
    2.3 If we contact you we will do so by telephone call, text, email, via our
    mobile App, by publishing on our website, including by changing the charges
    or this Agreement as set out in clause 1.1, by notifying you by recorded
    message or other means.
  </p>

  <h3>3. PROVISION OF THE SERVICES</h3>

  <p>
    3.1  We are providing you with mobile Services using approved Equipment, SIM Cards and network resources.
  </p>
  <p>
    3.2 These Conditions are the terms on which you may use the Services, Equipment and Software.
  </p>
  <p>
    3.3 Services will be provided within our network area in the UK and by roaming on to other networks.
  </p>

  <h3>4. BASIS OF CONTRACT</h3>

  <p>
    4.1 The Order constitutes an offer by the Customer to purchase Services in accordance with these Conditions.
  </p>
  <p>
    4.2 The Order shall only be deemed to be accepted when the Supplier either:
  </p>
  <p>
    (a) issues written acceptance of the Order; or
  </p>
  <p>
    (b) provides written confirmation that the Services have been made live;
  </p>
  <p>
    at which point and on which date the Contract shall come into existence.  All start dates for the Services are estimates only and should not be relied upon by the Customer.
  </p>
  <p>
    4.3 The Contract shall run from the Commencement Date for a minimum period of 12 months
  </p>
  <p>
    4.4 If a Customer does not serve notice to renew on or before the expiry of the contract period, then the Contract
    will continue until terminated by either party providing the other with not less than 90 calendar days’
    written notice.
  </p>
  <p>
    4.5 The Contract shall be renewed automatically at the end of the contract period, unless the Customer gives the Supplier not less than 90 calendar days’ written notice of the Customer’s intention to terminate the Contract, no more than 90 calendar days and no less than 30 calendar days before the last day of the contract period.
  </p>
  <p>
    4.6 If the Contract renews under the clauses above, the Contract shall continue to run for a further minimum period of 12 months
  </p>
  <p>
    4.7 The Customer has the right to change / amend their service at anytime during the 12 months without any charges
  </p>
  <p>
    4.8 Boshhh Ltd will obtain references from Credit Reference agencies, including submitting information to these
    credit reference agencies as and when appropriate.
  </p>

  <h3>What's a credit agreement?</h3>

  <ul style="font-weight: 300;">
    <li>A credit agreement is a type of loan that lets you spread out the total cost of a new device/accessory over several months.</li>
    <li>We'll lend you the money for the device/accessory. You can then repay the loan in monthly instalments.</li>
    <li>The credit agreement we offer is 0% interest, so you won't pay more for the device/accessory than if you bought it outright.</li>
    <li>We do not need to be regulated by the Financial Conduct Authority as we will only offer contract within 12 months period and no more than that</li>
  </ul>

  <h3>5. SUPPLY OF SERVICES</h3>

  <p>5.1 The Supplier shall supply the Services to the Customer in accordance with the Order in all material respects.</p>
  <p>5.2 The Supplier reserves the right to amend the Order if necessary to comply with any applicable law or regulatory requirement, or if the amendment will not materially affect the nature or quality of the Services, and the Supplier shall notify the Customer in any such event.</p>
  <p>5.3 The Supplier warrants to the Customer that the Services will be provided using reasonable care and skill.</p>
  <p>5.4 Any start dates specified within the Order are estimated start dates only. The Supplier cannot guarantee the date on which the Services will start and cannot be held liable for any loss or damage arising from any change to the estimated start date.</p>




  <h3>6. YOUR BUNDLE ALLOWANCE</h3>

  <p>
    6.1 Unless specified otherwise, a bundle lasts for a period of 30 days, from
    the day it is added to your account until 23.59 GMT or BST, whichever is in
    force, on day 30 (the “Bundle Period”). Your next bundle will automatically
    renew on the following day.
  </p>

  <p>
    6.2 If you exhaust your data allowance before the end of the 30 day Bundle
    Period, you may contact us and request a renewal of your next 30 day bundle,
    provided you have enough credit. We may also offer other payment methods to
    renew your bundle, as set out on our website, or else your service will be provided on a standard Pay As You Go Rates.
  </p>

  <p>
    6.3 Each time you use your Services your bundle allowance will be reduced
    according to the duration and type of call or message or the amount of data
    used. Out-of-bundle charges apply for usage not included in your bundle and
    you must have sufficient credit available. If you use all your data
    allowance you will no longer be able to use data until your next bundle
    allowance is renewed. If you have any remaining bundle allowance at the end
    of a Bundle Period it will expire and will not be rolled-over into the next
    period, unless we notify you otherwise.
  </p>

  <p>
    6.4 The pricing information detailed in our
    <a role="button" (click)="navigate('/calls-data-roaming-charges')"
    >Calls, Data & Roaming Charges</a
    > sets out all current charges including details of the
    calls and other traffic types included within the bundle allowance; out of
    bundle charges for usage not included in the bundle; and standard pay as you
    go rates if you do not renew your bundle. All charges include VAT where
    applicable.
  </p>

  <p>
    6.5 If you don’t want your bundle to automatically renew, or you want to
    change your bundle allowance at the next renewal date, you can use the
    settings on our App or contact us in any of the ways set out in clause 2.2.
  </p>

  <p>
    6.6 If we need to terminate or change your bundle allowance, the bundle
    charge applicable to your bundle (the “Bundle Charge”) or any bundle terms
    to your disadvantage we will give you notice and the terms of clause 7 (Our
    Rights to Make Changes) shall apply.
  </p>

  <p>
    6.7 You will receive a credit allowance of up to £100 per calendar month
    (the “Credit Allowance”) which can be applied to your account via the ‘My
    Mobile’ portal. The Credit Allowance may be used for add ons such as
    additional data, data roaming, international calls and premium texts.
  </p>

  <h3>7. PAYMENT</h3>

  <p>
    7.1 At the end of each Bundle Period you will be charged the relevant Bundle
    Charge for the following Bundle Period. When you order our SIM card we will
    take payment for the first Bundle Charge by debit card or other
    payment methods we permit.

  </p>

  <p>
    7.2 The Charges for the Services shall be calculated by the application of the appropriate rate per unit of time, taking into account destinations and categories of call:
  </p>
  <p>
    (a) the Charges shall be calculated in accordance with the Tariff, as set out in the current price list at the date of the Contract;
  </p>
  <p>
    (b) the Supplier shall be entitled to charge connection fees, disconnection fees, re-connection fees and/or annual or monthly service charges;
  </p>
  <p>
    (c) in the event that the Customer fails to comply with clause 12.1(b), the Supplier shall be entitled to charge a non-return of hardware charge in accordance with the Tariff
  </p>
  <p>
    7.3 If you do not agree with your payment amount, you must notify us with
    reasons as soon as possible – and at least within one month. This will not
    suspend your payment obligations.
  </p>
  <p>
    7.4 The Supplier may vary the Charges or Out Payment for any Service from time to time by giving the Customer not less than 30 days’ prior written notice.
  </p>
  <p>
    7.5 Where an amendment to the Charges under clause 1 will have a material adverse effect on the Customer’s business, the Customer may terminate this agreement by providing the Supplier with not less than 90 days’ prior written notice.
  </p>

  <p>
    7.6 The Customer shall pay each invoice submitted by the Supplier:
  </p>
  <p>
    (a) within 14 days of the date of the invoice or in accordance with any credit terms agreed by the Supplier and confirmed in writing to the Customer; and
  </p>
  <p>
    (b) in full and in cleared funds to a bank account nominated in writing by the Supplier, and time for payment shall be of the essence of the Contract.
  </p>
  <p>
    7.7 All amounts payable by the Customer are exclusive of amounts in respect of value added tax chargeable from time to time (VAT). Where any taxable supply for VAT purposes is made under the Contract by the Supplier to the Customer, the Customer shall, on receipt of a valid VAT invoice from the Supplier, pay to the Supplier such additional amounts in respect of VAT as are chargeable on the supply of the Services at the same time as payment is due for the supply of the Services.
  </p>

  <p>
    7.8 All amounts due to the Supplier from the Customer under the Contract shall be paid in full without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as required by law). The Supplier shall be entitled to deduct any sum owing to the Customer from any sums owed by the Customer to the Supplier.
  </p>
  <p>
    7.9 Rebates due to the Customer shall be notified to the Customer within 15 Business Days of the relevant billing period and, subject to clause 7.11, will be paid within 35 Business Days of such notification.
  </p>

  <p>
    7.10 You are responsible to pay your charges in full even if incurred by
    someone else using your Services and even if you dispute the charges.
  </p>
  <p>
    7.11 Credits due to the Customer and not paid in accordance with this Agreement will be retained for a period of 12 months from the date they were notified to the Customer. If either of the circumstances detailed in the clause above has not been rectified before that date, then the credits will be lost.
  </p>
  <p>
    7.12 Customers will be required to pay the Charges via direct debit or a payment plan agreed with the Supplier. In the event that the Customer pays the Charges via an alternative non-agreed method a payment processing charge will be applied as detail in the Customer’s invoice(s).
  </p>
  <p>
    7.13 Charges for all mobile usage may be invoiced up to 12 months in arrears and standard payment terms shall apply to these Charges.
  </p>

  <p>
    7.15 We will report all successful payments made by you under your Agreement
    to credit referencing agencies. This may help to improve your credit rating
    and credit score although we cannot guarantee this.
  </p>

  <p>
    7.16 We do not accept any liability if your credit rating or credit score do
    not improve or if your credit rating or credit score is negatively affected
    if for example you choose to make a payment to us under your Agreement
    instead of discharging your other financial commitments.
  </p>

  <h3>8. PROVISION OF THE SERVICES</h3>

  <p>
    8.1 We will aim to provide you with a continuous service with the quality
    expected from a competent provider using reasonable care and skill, but we
    cannot guarantee that the Services will be fault-free, meet your exact
    requirements or be available continuously or everywhere in the UK. Please
    check the coverage checker at boshhh.com.
  </p>

  <p>
    8.2 There may be factors outside of our control that impact availability or
    quality, including environmental factors such as weather or type of
    building, the number of people using the network and faults or maintenance
    or repair work in the network(s) we use to provide the Services. Please
    contact us if you experience a Service issue and we’ll try to fix it.
  </p>

  <p>
    8.3 We will provide you with a SIM and a number. We own the SIM and number
    at all times and you must return either if we request for example, the
    number is required by a regulatory body. You are responsible for other
    people who use your Services complying with this Agreement. You must call us
    immediately if your SIM is lost, stolen or damaged. Refunds for any Services
    you have paid for in advance will be made in our sole discretion. You will
    remain responsible for all charges incurred as a result of unauthorised use
    of your SIM until you notify us and we can suspend your Services. We may
    charge you for a replacement SIM card.
  </p>

  <p>
    8.4 Devices which you bring to the network used by us to provide the
    Services are your responsibility, including their compatibility and
    functionality. We are not responsible if data is lost or the device damaged
    during any unlocking process and you should back-up or store separately such
    data.
  </p>

  <p>
    8.5 You are able to use the Services to call the emergency services. Your
    number will be shown to the emergency operator. When making an emergency
    call, your location information will be provided to the emergency operator
    to the extent technically feasible.
  </p>

  <p>
    8.6 If you use the Services in the European Economic Area we will provide
    Services utilising our roaming partners from time-to-time and in line with
    applicable legislation and any fair use policy published on our website.
    Please see the Roaming section of our Help pages on our website for further
    information.
  </p>

  <h3>9. YOUR / OUR RIGHTS TO END THE CONTRACT OR SUSPEND SERVICES</h3>

  <p>
    9.1 We may terminate this Agreement at any time as set out in clause 2.2. You will not be able to claim back any unused airtime credit on your account or any sums in relation to any unused part of the Bundle Period.
  </p>

  <p>
    9.2 We may terminate this Agreement at any time by giving notice to you,
    without any liability, if you: a. fail to pay the charges in full or on
    time;; b. breach this Agreement in a material way and do not put it right
    (where it is possible to do so) within a period of 15 working days after a
    request to do so; c. all of the Services are no longer available for use for
    a period longer than seven days or, any agreement giving us access to the
    network we use to provide the Services or our right to provide the Services
    is suspended or terminated; or d. if there is no chargeable activity on your
    SIM card for 90 days or more. After 60 days of no chargeable activity, we
    will first contact you requesting you to make a chargeable outbound call,
    send a text or use data (calls to 500 - customer services or 999 - emergency
    services, are free and are not classed as a chargeable event). If you then
    fail to do so within 30 days from the date of that contact we will contact
    you again to confirm that you have not used the Services for 90 consecutive
    days and your mobile services will be disconnected and you will lose any
    credit held on your account.
  </p>

  <p>
    9.3 If this Agreement is ended: a. your access to the Services will be
    disconnected and you will lose any remaining bundle allowance; b. any unused
    airtime credit will be lost; and c. you will no longer be entitled to use
    any number associated with your SIM card and we will recycle it unless you
    have ported the number to another network.
  </p>

  <p>
    9.4 We may need to suspend the provision of the Services: a. for reasons
    outside of our control where this is strictly necessary; b. for operational
    or emergency reasons where we have to interrupt the Services, for example,
    due to maintenance of the network we use to provide the Services, or bar
    certain numbers to prevent fraud; or c. if asked to do so by government or
    competent authorities or if required by law.
  </p>

  <p>9.5 If the Services are suspended, this Agreement will still continue.</p>

  <p>
    9.6 We may choose to suspend the Services in the situations where we have
    the right to terminate this Agreement, as an alternative or additional
    right. We reserve the right not to lift the suspension until you confirm
    that you will use the Services only in accordance with the terms of this
    Agreement. We will continue to provide access to emergency services.
  </p>

  <h3>10. OUR RIGHTS TO MAKE CHANGES</h3>

  <p>
    10.1 We may need to change our Services or these terms and conditions at any
    time, for legal or regulatory reasons, due to network or technology changes
    or for other good technical or commercial reasons, for example, changes to
    or withdrawal of Services if they become technically impractical or are not
    fulfilling their economic purpose for us or for you. We may need to change
    our charges at any time.
  </p>

  <p>
    10.2 We will publish such changes on our website, including by changing our
    Pricing Information or these terms available at boshhh.com. We may also
    choose to notify you by phone, text, email or the other methods stated in
    clause 2.3.
  </p>

  <p>
    10.3 If any change is likely to cause material detriment to you in our
    reasonable opinion, we will give you at least one month’s notice by email or
    text or via our mobile App. If you do not accept such change, you may cancel
    this Agreement by notifying us at any time before any such materially
    detrimental change comes into force.
  </p>

  <p>
    10.4 Your continued use of the Services after the date of any change will be
    deemed to be your acceptance of the change.
  </p>

  <h3>11. RETURNS (INCLUDING IF YOU HAVE CHANGED YOUR MIND)</h3>

  <p>
    11.1 You may notify us you want to cancel this Agreement at no cost (other
    than a pro rata amount of the Bundle Charge, plus any other charges already
    incurred, to the date you let us know you want to cancel) and without giving
    any reason within 14 days from the date after this Agreement started or you
    received your SIM card (the “Cooling Off Period”). You will not receive a
    refund of any free airtime credit which you received from us (if any). To
    cancel this Agreement within the Cooling Off Period please email us at
    goodbye@boshhh.com. You will need to return the SIM card to us at your cost.
  </p>

  <p>
    11.2 If your SIM is defective at any time and you obtained it from us please
    get in touch with us.
  </p>

  <p>
    11.3 This clause 8 does not affect your legal rights as a consumer which
    cannot be limited under English law (please contact your local trading
    standards or citizens advice bureau for more information on your rights).
  </p>

  <p>11.4 Our Refund Policy applies to your Agreement.</p>

  <h3>12. RESOLVING DISPUTES</h3>

  <p>
    12.1 Please contact us if you have a complaint or query. You can contact us
    in the ways set out in clause 2.2. We will work closely with you to resolve
    any disputes that may arise. However if we cannot reach a mutually
    satisfactory resolution, you may refer the dispute to an independent dispute
    resolution body that we are a member of. Further details can be found in our
    Code of Practice available at boshhh.com. Alternatively, if you’re not happy
    with a product or service bought online, you can submit a complaint through
    the European Online Dispute Resolution (ODR) website, which can be accessed
    here www.ec.europa.eu/consumers/odr/main.
  </p>

  <h3>13. LIMITATION OF LIABILITY</h3>

  <p>
    13.1 We are only legally responsible to you: a. as set out in this Agreement
    or in accordance with your legal rights; and b. for your loss or damage
    which we directly cause.
  </p>

  <p>
    13.2 You must tell us of any claim against us promptly while we still retain
    relevant records.
  </p>

  <p>13.3 Our total liability to you is limited to a maximum of £250.</p>

  <p>
    13.4 We exclude all liability to you for: a. loss or damage of a type that
    could not reasonably have been expected by both of us when we entered this
    Agreement; b. loss of income, profit, business, savings, lost opportunity or
    wasted expense; c. loss or damage to your equipment, software, data or
    content unless due to our negligence; d. loss or damage related to third
    party sites or other internet usage accessed through our service, including
    their content, any goods and services you obtain from them or viruses; e.
    your failure to follow any reasonable instruction from us in relation to the
    Services, for example in relation to installing your SIM.
  </p>

  <p>
    13.5 Nothing in this agreement limits or removes our liability for our
    fraud, for death or injury caused by our negligence, or relating to consumer
    rights that cannot be limited under English law.
  </p>

  <p>
    13.6 Any part of these terms that is not legally effective will not affect
    the remainder of these terms, which will remain effective. This clause 10
    will continue to apply even after the end of this Agreement.
  </p>

  <p>
    13.7 If we are unable to meet our obligations to you due to events that are
    out of our reasonable control (including environmental factors such as
    lightning, flood and exceptionally severe weather and acts or omissions of
    persons for whom we are not responsible (including other telecommunication
    service providers or roaming partners), we will not be liable for our
    failure under such circumstances.
  </p>

  <p>
    13.8 Please note that we shall not be liable to you for any loss or damage
    in the event that our Services cannot be provided due to a failure or
    collapse of the main network.
  </p>

  <h3>14. DATA PROTECTION</h3>

  <p>
    14.1 We will only use personal data about you as set out in clauses 4.5 to
    4.6 and our Privacy Policy which is available on our website at boshhh.com.
  </p>

  <h3>15. OTHER IMPORTANT TERMS</h3>

  <p>
    15.1 This Agreement is personal to you. You may not transfer your rights or
    responsibilities to someone else without our consent. We may transfer our
    rights and responsibilities without your consent and we’ll ensure that your
    rights under this Agreement are not affected.
  </p>

  <p>
    15.2 This Agreement does not confer any benefit on any third party under the
    Contracts (Rights of Third Parties) Act 1999.
  </p>

  <p>
    15.3 This Agreement shall be interpreted under English law and any court
    proceedings related to a dispute will be heard in the English courts.
  </p>

  <p>
    15.4 Even if we delay in enforcing this Agreement, we can still enforce it
    later. If we do not insist immediately that you do anything you are required
    to do under these terms, or if we delay in taking steps against you in
    respect of your breaking this Agreement, that will not mean that you do not
    have to do those things and it will not prevent us taking steps against you
    at a later date.
  </p>

  <p>
    15.5 We will monitor and record some calls or webchat communications to
    customer services for training and quality control purposes or operational
    or compliance reasons. We may also record all calls to the emergency
    services. Any action taken by us under this clause 12.5 shall be subject to
    our Call Recording Policy.
  </p>

  <ul>
    <li>
      <a role="button" (click)="navigate('/call-record-policy')">Call Record Policy</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/calls-data-roaming-charges')"
        >Calls Data Roaming Charges</a
      >
    </li>
    <li>
      <a role="button" (click)="navigate('/refund-policy')">Refund Policy</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/code-of-practice')">Code Of Practice</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/how-to-and-faqs')">How To And FAQ's</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/privacy-policy')">Privacy Policy</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/cookie-policy')">Cookie Policy</a>
    </li>
    <li>
      <a role="button" (click)="navigate('/returns-policy')">Returns Policy</a>
    </li>
  </ul>
</div>
