import { Component, OnInit } from '@angular/core';
import { IFooterModel } from "../../../../../libs/boshhh/src/lib/components/footer/footer.model";

@Component({
  selector: 'app-footer',
  template: `
  `
})
export class FooterComponent implements OnInit {
   constructor() {}

  ngOnInit(): void {}
}
